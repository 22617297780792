import { Component, EventEmitter, Output, Input } from '@angular/core';
import { BaseMessageComponent } from '../../../utils/base-message.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-required-message',
  templateUrl: './confirm-required-message.component.html',
  styleUrls: ['./confirm-required-message.component.scss']
})
export class ConfirmRequiredMessageComponent extends BaseMessageComponent {
  @Input() browserIEorFF:boolean = false;
  @Output() resend = new EventEmitter<any>();

  constructor(private titleService: Title) {
    super();
  }
  ngOnInit(){
    this.titleService.setTitle('Confirm Account | Vistana Signature Experience');
  }
  async resendConfirmation() {
    this.startProcessing();
    this.resend.emit();
    
  }
}
