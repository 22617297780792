import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../../utils/password-validation';
import { PasswordRequirementsComponent } from '../../utils/password-requirements/password-requirements.component';
import { BaseFormComponent } from '../base-form.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-update-form',
  templateUrl: './password-update-form.component.html',
  styleUrls: ['./password-update-form.component.scss']
})
export class PasswordUpdateFormComponent extends BaseFormComponent {
  @ViewChild('passwordReqComponent', { static: true }) private passwordReqComponent: PasswordRequirementsComponent;
  showPwHelp = false;
  success = false;
  @Input() returnUrl: string;

  @Output()
  updatePassword = new EventEmitter<FormGroup>();

  constructor(private router: Router) {
    super();
  }
  processingSub = false;
  // Eliminate spaces before or after a value. Accepts input name as argument.
    trimValue(val){
      const item = this.form.get(val);    
      if(item.value){item.setValue(item.value.trim())}      
    }
  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      oldPassword: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      password: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      confirmPassword: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  cancelForm() {
    this.form.reset();
    this.form.setErrors(null);
    if(this.returnUrl === "vistana.dashboard") {
      window.location.href = environment.vistana_baseurl + '/dashboard';
    } else {
      this.router.navigate(['/settings/account-summary']);
    }
  }

  update() {
    this.form.setErrors(null);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.startProcessing();
    this.updatePassword.emit(this.form.value);
  }
 //Toastr
 messageDisplay:boolean = false;

 toastrShowing(evt){  
   this.messageDisplay = evt;
 }
     
 messageData:any = {} ;
 
 messageDataUpdate = (outcome) =>{ 
   this.messageData = new Object;     
   this.messageData.type = outcome;
   if(outcome === 'success'){
     this.messageData.copy=['Successfully submitted.'];        
   } else if(outcome === 'error'){
     this.messageData.copy=['Account update failed. Please resubmit.'];
   }
   this.messageData.copy ? this.toastrShowing(true) : null;   
 }

}
