import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/pages/login/login.component';
import { LogoutComponent } from './authentication/pages/logout/logout.component';
import { RegistrationComponent } from './authentication/pages/registration/registration.component';
import { ResetComponent } from './authentication/pages/reset/reset.component';
import { ChallengeComponent } from './authentication/pages/challenge/challenge.component';
import { ManageAccountComponent } from './authentication/pages/manage-account/manage-account.component';
import { AuthorizeComponent } from './authentication/pages/authorize/authorize.component';
import { ConnectOwnershipComponent } from './authentication/pages/connect-ownership/connect-ownership.component';
import { LoggedInGuard } from './authentication/guards/logged-in.guard';
import { AuthenticationGuard } from './authentication/guards/authentication.guard';
import { ConfirmAccountComponent } from './authentication/pages/confirm-account/confirm-account.component';
import { VerifyEmailComponent } from './authentication/pages/verify-email/verify-email.component';
import { VerifyPhoneComponent } from './authentication/pages/verify-phone/verify-phone.component';
import { PageNotFoundComponent } from './authentication/pages/page-not-found/page-not-found.component';
import { AccountSettingsComponent } from './authentication/pages/account-settings/account-settings.component';
import { GenericErrorComponent } from './authentication/pages/generic-error/generic-error.component';
import { InvalidVerificationComponent } from './authentication/pages/invalid-verification/invalid-verification.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [LoggedInGuard]},
  {path: 'logout', component: LogoutComponent},
  {path: 'register', component: RegistrationComponent, canActivate: [LoggedInGuard]},
  {path: 'confirm-account', component: ConfirmAccountComponent},
  {path: 'reset', component: ResetComponent, canActivate: [LoggedInGuard]},
  {path: 'challenge', component: ChallengeComponent, canActivate: [LoggedInGuard]},
  {path: 'manage-account', component: ManageAccountComponent, canActivate: [AuthenticationGuard]},

  {path: 'settings', loadChildren: () => import('./authentication/pages/my-account/my-account.module').then(m => m.MyAccountModule), canActivate: [AuthenticationGuard] },
  {path: 'authorize', component: AuthorizeComponent},
  {path: 'settings/ownership', component: ConnectOwnershipComponent, canActivate: [AuthenticationGuard]},
  {path: 'account-settings', component: AccountSettingsComponent, canActivate: [AuthenticationGuard]},
  {path: 'verify-email', component: VerifyEmailComponent, canActivate: [AuthenticationGuard]},
  {path: 'verify-phone', component: VerifyPhoneComponent, canActivate: [AuthenticationGuard]},
  {path: 'error', component: GenericErrorComponent},
  {path: 'invalid-verification', component: InvalidVerificationComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
