import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invalid-verification',
  templateUrl: './invalid-verification.component.html',
  styleUrls: ['./invalid-verification.component.scss']
})

export class InvalidVerificationComponent implements OnInit {

  title: string = 'OUR APOLOGIES';
  isMobile: boolean = false;

  constructor(private titleService: Title, private route: ActivatedRoute,) { }

  ngOnInit() {
    this.titleService.setTitle('Our Apologies | Vistana Signature Experiences');
    window.digitalData = {pageInfo: {}, userInfo: {}};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Invalid Verification';
    const params = this.route.snapshot.queryParams;
    if (params.type === 'mobile') {
      this.isMobile = true;
    }
  }

}