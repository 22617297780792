import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { Title } from '@angular/platform-browser';
import { VerificationStatusService } from '../../services/verification-status.service';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent implements OnInit {

  deliveryDetails: any;
  title: string = 'Verify Phone';
  verifyFail: boolean = false;
  verifyFailMsg: string;

  constructor(private route: ActivatedRoute, private router: Router,
              private authService: AuthenticationService, private launch: AdobeLaunchService, 
              private titleService: Title) {
  }

  ngOnInit() {
    this.titleService.setTitle('Verify Phone | Vistana Signature Experiences');
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Verify Phone';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    const params = this.route.snapshot.queryParams;
    this.authService.getCurrentUser(true).then(user => {
      if (user.attributes.phone_number_verified) {
        this.router.navigate(['/settings']);
        return;
      }

      if (params.code == null) {
        this.authService.resendVerifyPhone().then(() => {
          this.deliveryDetails = 'sent';
          this.launch.eventTrack('Verify Phone - Resent', { username: user.username, preferredUsername: user.attributes.preferred_username, sub: user.attributes.sub, customOwnerStatus: user.attributes['custom:owner_status'] });
        }).catch(err => {
          console.error(err);
          this.verifyFail = true;
          if(err.code === 'LimitExceededException') {
            this.verifyFailMsg = 'You have made multiple mobile phone verification requests. Please confirm your phone number is correct. More incorrect attempts may result in more delays. Please try again later.';
          } else {
            this.verifyFailMsg = 'Something went wrong. We\'re working on it! Please try again later. ';
          }
        });
      } else {
        const verifyAttributeForm = new FormBuilder().group({
          verificationCode: [params.code, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
        });
        if (!verifyAttributeForm.valid) {
          this.router.navigate(['/invalid-verification'], { queryParams: { type: 'mobile' } });
          return;
        }
        this.authService.verifyPhone(verifyAttributeForm.get('verificationCode').value).then(() => {
          this.launch.eventTrack('Verify Phone - Success', { username: user.username, preferredUsername: user.attributes.preferred_username, sub: user.attributes.sub, customOwnerStatus: user.attributes['custom:owner_status'] });
          this.router.navigate(['/settings']);
        }).catch(err => {
          console.error(err);
          this.launch.eventTrack('Verify Phone - Failed', { username: user.username, preferredUsername: user.attributes.preferred_username, sub: user.attributes.sub, customOwnerStatus: user.attributes['custom:owner_status'] });
          this.router.navigate(['/invalid-verification'], { queryParams: { type: 'mobile' } });
        });
      }
    });
  }
}
