import {AbstractControl} from '@angular/forms';

export class CommsValidation {

  static SelectComms(form: AbstractControl) {

      const newsletters = form.get('newsletters').value;
      const promotions = form.get('promotions').value;
      const specials = form.get('specials').value;
      const optOutComms = form.get('optOutComms').value;
    //Checks if all communication checkboxes are unchecked. If so, sets the error SelectComms
    if (specials === false  && promotions === false  && newsletters === false && optOutComms === false) { 
        form.get('optOutComms').setErrors( {SelectComms: true} );      
    } else {        
        return null;
    }
  }
}