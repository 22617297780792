import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  trigger,
  state,
  style,
  animate,
  transition,  
} from '@angular/animations';

@Component({
  selector: 'app-button-spinner',
  templateUrl: './button-spinner.component.html',
  styleUrls: ['./button-spinner.component.scss'],
  animations: [
    trigger('spinnerTrigger',[
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.3s', style({ opacity: 0 }))
      ])
    ]),  
  ]
})

export class ButtonSpinnerComponent {

  faSpinner = faSpinner;
  
  @Input() isLoading: boolean = false;

  

  constructor() {}


}