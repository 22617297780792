import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import Auth from '@aws-amplify/auth';
import { CookieService } from 'ngx-cookie-service';
import { VerificationStatusService } from './verification-status.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  expApiBaseUrl: string;
  idp_baseurl:string;
  apiKey: string;

  constructor(private http: HttpClient, private cookie: CookieService, private verificationStatusService: VerificationStatusService,private router: Router) {
    Auth.configure(environment.Auth);
    this.expApiBaseUrl = environment.api_baseurl;
    this.idp_baseurl = environment.idp_baseurl;
    this.apiKey = environment.api_key;
  }

  auth(username, password) {
    username = username.toLowerCase();
    const res = Auth.signIn(username, password);    
    this.cookie.set('signedIn', 'true', null, '/');
    this.cookie.set('sessiat', Math.round(new Date().getTime() / 1000) + '', null, '/');   
    return res;
  }

  forcedUpdatePassword(user, password) {
    const res = Auth.completeNewPassword(user, password, user.challengeParam.requiredAttributes);
    this.cookie.set('signedIn', 'true', null, '/');
    this.cookie.set('sessiat', Math.round(new Date().getTime() / 1000) + '', null, '/');
    return res;
  }

  disconnectOwnership(user){
    return this.http.delete(this.expApiBaseUrl + '/exp/v1/users/me/disconnect', {
      headers: {
        'api_key': this.apiKey,
        'Authorization': 'Bearer ' + user.signInUserSession.accessToken.jwtToken,
        'Content-Type': 'application/json',
      }
    })
  }

  getOwnerInfo(user) {    
    return this.http.get(this.expApiBaseUrl + '/exp/v1/owners/me', {
      headers: {
        'api_key': this.apiKey,
        'Authorization': 'Bearer ' + user.signInUserSession.accessToken.jwtToken,
        'Content-Type': 'application/json',
      }
    })
  }

  getContactInfo(user) {    
    return this.http.get(this.expApiBaseUrl + '/exp/v1/users/me/contact-info', {
      headers: {
        'api_key': this.apiKey,
        'Authorization': 'Bearer ' + user.signInUserSession.accessToken.jwtToken,
        'Content-Type': 'application/json',
      }
    })
  }

  postContactInfo(user, form) {    
    return this.http.post(this.expApiBaseUrl + '/exp/v1/users/me/contact-info', form, {
      headers: {
        'api_key': this.apiKey,
        'Authorization': 'Bearer ' + user.signInUserSession.accessToken.jwtToken,
        'Content-Type': 'application/json',
      }
    })
  }

  getCommPrefs(user) {    
    return this.http.get(this.expApiBaseUrl + '/exp/v1/users/me/comm-pref?email=' + user.attributes.email, {
      headers: {
        'api_key': this.apiKey,
        'Authorization': 'Bearer ' + user.signInUserSession.accessToken.jwtToken,
      }
    })
  }

  postCommPrefs(user, form) {   
    if(user.attributes['custom:owner_status'] === '0'){
      form.preferredEmail = user.attributes.email;
    }
    return this.http.post(this.expApiBaseUrl + '/exp/v1/users/me/comm-pref', form, {
      headers: {
        'api_key': this.apiKey,
        'Authorization': 'Bearer ' + user.signInUserSession.accessToken.jwtToken,
        'Content-Type': 'application/json',
      }
    })
  }
  // register(username, password, email, phone): Promise<any> {
  //   email = email.toLowerCase();
  //   username = username.toLowerCase();

  //   const attributes = {
  //     email,
  //     phone_number: ''
  //   };

  //   if (phone) {
  //     attributes.phone_number = phone;
  //   }

  //   return Auth.signUp({
  //     username,
  //     password,
  //     attributes
  //   });
  // }
  register(event): Promise<any> {

    var addressObject:any = {
      locality: event.city,
      postal_code: event.postal,
      region: event.stateProvince,
      country: event.countryCode,
    }
    var streetAddress ="";
    if(event.address1){streetAddress += event.address1}
    if(event.address2){streetAddress += "||" + event.address2}
    if(event.address3){streetAddress += "||" + event.address3}
    if(streetAddress !== ""){addressObject.street_address = streetAddress}
    var address= JSON.stringify(addressObject);
    var given_name = event.firstname;
    var family_name = event.lastname;
       
    const attributes = {
      email:event.email.toLowerCase(),
      address,
      phone_number: event.phone ? event.phone.replace(/[().]/g,'') : event.phone = "",
      given_name:event.firstname,
      family_name: event.lastname,
      "custom:comm_newsletters": event.newsletters ? "1":"0",
      "custom:comm_promotions": event.promotions ? "1":"0",
      "custom:comm_specials": event.specials ? "1":"0",
      "custom:owner_status": "0"
    };


    if (event.phone) {
      attributes.phone_number = event.phone.replace(/[().]/g,'');
    }
    var username = event.username.toLowerCase();
    var password = event.password; 

    return Auth.signUp({
      username,
      password,
      attributes
    });
  }

  connectOwnership(user, form){
    form.username = user.username;  
    return this.http.post(this.expApiBaseUrl + '/exp/v1/users/me/connect', form,{

      headers: {
        'api_key': this.apiKey,
        'Authorization': 'Bearer ' + user.signInUserSession.accessToken.jwtToken,
        'Content-Type': 'application/json',
      }
    })
  }

  clearSession(returnToURL:string){ 
    return this.http.get(`${this.idp_baseurl}/sso/logoutsps`,{params:{returnTo: this.idp_baseurl+returnToURL}});
  }
  clearSessionAlt(returnToURL:string,connected=false){
    //Add else if for second query string connected
    !connected ? window.location.href = `${this.idp_baseurl}/sso/logoutsps?returnTo=${returnToURL}`:window.location.href = `${this.idp_baseurl}/sso/logoutsps?returnTo=${returnToURL}?connected=true`;
    
  }

  resetByContract(form) {
    
    return this.http.post(`${this.expApiBaseUrl}/exp/v1/owners/me/reset-password`, form, {
      headers: {
        'api_key': this.apiKey,      
        'Content-Type': 'application/json',  
      }
    });

    // return this.http.post('http://localhost:8080/owners/me/resetByContract', form, {
    //   headers: {
    //     'api_key': this.apiKey,      
    //     'Content-Type': 'application/json',  
    //   }
    // });
  }

  //From legacy:
  // apiKey = 'qsh878drjwnvky34j8f9599p';
  // disconnectOwnership(user){
  //   return this.http.post('https://api-qa.vistana.com/exp/v2/disconnect', null,{
  //     headers: {
  //       'api_key': this.apiKey,
  //       'Authorization': 'Bearer ' + user.signInUserSession.accessToken.jwtToken,
  //       'Content-Type': 'application/json',
  //     }
  //   })
  // }

  reset(username): Promise<any> {
    username = username.toLowerCase();
    return Auth.forgotPassword(username);
  }

  confirmReset(username, code, newPassword): Promise<any> {
    username = username.toLowerCase();
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  async isAuthenticated() {
    try {
      const session = await Auth.currentSession();
      const secondsSinceSignIn = (Math.round(new Date().getTime() / 1000)) - +this.cookie.get('sessiat');
      if (session && this.cookie.get('signedIn') === 'true' && secondsSinceSignIn < 3600) {      
        return true;
      }     
      window.localStorage.clear();
      this.cookie.delete('sessiat');
      this.cookie.delete('signedIn');
    } catch (err) {}

    return false;
  }

  async updateUser(updateAttributes, deleteAttributes) {
    const user = await this.getCurrentUser(true);

    if (deleteAttributes.length > 0) {
      user.deleteAttributes(deleteAttributes, () => {});
    }

    await Auth.updateUserAttributes(user, updateAttributes);
    return this.getCurrentUser(true);
  }

  async updatePassword(oldPassword, newPassword) {
    const user = await this.getCurrentUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  resendVerifyAccount(username): any {
    username = username.toLowerCase();
    return Auth.resendSignUp(username);
  }

  verifyAccount(username, verificationCode) {
    username = username.toLowerCase();
    return Auth.confirmSignUp(username, verificationCode);
  }

  async resendVerifyEmail() {
    return Auth.verifyCurrentUserAttribute('email');
  }

  async verifyEmail(code) {
    await Auth.verifyCurrentUserAttributeSubmit('email', code);
    return this.getCurrentUser(true);
  }

  async resendVerifyPhone() {
    return Auth.verifyCurrentUserAttribute('phone_number');
  }

  async verifyPhone(code) {
    await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
    return this.getCurrentUser(true);
  }

  getCurrentUser(ignoreCache = false) {
    return Auth.currentAuthenticatedUser({
      bypassCache: ignoreCache
    }).then(user => {
      if(!user.attributes){       
        this.router.navigate(['/logout']);
        return;
      }
      let unverifiedCount: number = 0;
      this.verificationStatusService.updateEmailVerificationStatus(user.attributes.email_verified, user.attributes.email);
      this.verificationStatusService.updatePhoneVerificationStatus(user.attributes.phone_number_verified, user.attributes.phone_number);
      if(user.attributes.email_verified){
        unverifiedCount++;
      }
      if(user.attributes.phone_number_verified){
        unverifiedCount++;
      }
      this.verificationStatusService.updateUnverifiedCount(unverifiedCount);          
      return user;
    }).catch(e=>{
      this.router.navigate(['/login']);      
    });   
  }

  public getSession() {
    return Auth.currentSession();
  }

  logout() {
    this.cookie.delete('signedIn');
    this.cookie.delete('sessiat');
    window.digitalData = {pageInfo: {}, userInfo: {}};
    return Auth.signOut();
  }
}
