import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnerLookupFormComponent } from '../../forms/owner-lookup-form/owner-lookup-form.component';
import { AuthenticationService } from '../../services/authentication.service';
import { SsoService } from '../../services/sso.service';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-connect-ownership',
  templateUrl: './connect-ownership.component.html',
  styleUrls: ['./connect-ownership.component.scss']
})
export class ConnectOwnershipComponent implements OnInit {

  @ViewChild('ownerLookup', { static: false }) private ownerLookup: OwnerLookupFormComponent;
  status: any;
  user: any;
  footer: SafeHtml;
  title:string = 'Ownership Account';  
  showAccountNotFound;
  showErrorMessage;
  emailPhoneNotVerified;
  resetMessage;
  accountNotAssociated;
  duplicateOwner;
  showSuccess;
  inactiveOwner;
  accountDisabled;
  codeDeliveryDetails;

  constructor(private router: Router, private route: ActivatedRoute, private ssoService: SsoService,
              private authService: AuthenticationService, private launch: AdobeLaunchService, public sanitizer: DomSanitizer,private titleService: Title) {  
              }

  ngOnInit() {
    this.titleService.setTitle('Ownership Settings | Vistana Signature Experiences');
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Connect Ownership';

    this.authService.getCurrentUser(true)
      .then(user => {
        this.user = user;
        if (user.attributes['custom:owner_status'] === '1') {
          this.status = 'owner';
        } else {
          this.status = 'guest';
        }
      })
      .catch(err => {
        console.error(err);
        this.redirectToEndOfFlow();
      });

  }

  public connect(res: any):void {
    this.showAccountNotFound = res['showAccountNotFound'];
    this.showErrorMessage = res['showErrorMessage'];
    this.duplicateOwner = res['duplicateOwner'];
    this.showSuccess = false;
    this.inactiveOwner = res['inactiveOwner'];
    
  }

  redirectToEndOfFlow() {
    const params = this.route.snapshot.queryParams;
    const redirectUri = decodeURIComponent(params.redirect_uri);
    const stateParam = params.state;

    try {
      if (redirectUri && this.ssoService.isSpValid(redirectUri)) {
        this.router.navigate(['/authorize'], {queryParams: {redirect_uri: redirectUri, state: stateParam}});
      } else {
        this.router.navigate(['/settings']);
      }
    } catch (err) {
      this.router.navigate(['/settings']);
    }
  }

  async redirectToResetAndLogOut() {
    await this.authService.logout();
    this.router.navigateByUrl('/reset#ownership');
  }

  async logout() {
    await this.authService.logout();
    window.location.href = this.ssoService.getServicingSiteLogout();
  }
}
