import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { Title } from '@angular/platform-browser';
import { VerificationStatusService } from '../../services/verification-status.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  deliveryDetails: any;
  email: string;
  title: string = 'Verify Email';
  verifyFail: boolean = false;
  verifyFailMsg: string;

  constructor(private route: ActivatedRoute, private router: Router, 
              private authService: AuthenticationService, private launch: AdobeLaunchService, 
              private titleService: Title, private verificationStatusService: VerificationStatusService) {}

  ngOnInit() {
    this.titleService.setTitle('Verify Email | Vistana Signature Experiences');
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Verify Email';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    const params = this.route.snapshot.queryParams;
    this.authService.getCurrentUser(true).then(user => {
      this.email = user.attributes.email;
      if (user.attributes.email_verified) {
        this.router.navigate(['/settings']);
        return;
      }

      if (params.code == null) {
        this.authService.resendVerifyEmail().then(() => {
          this.deliveryDetails = 'sent';
          this.launch.eventTrack('Verify Email - Resent', { username: user.username, preferredUsername: user.attributes.preferred_username, sub: user.attributes.sub, customOwnerStatus: user.attributes['custom:owner_status'] });
        }).catch(err => {
          console.error(err);
          this.verifyFail = true;
          if(err.code === 'LimitExceededException') {
            this.verifyFailMsg = 'You have made multiple email verification requests. Please confirm your email is correct. More incorrect attempts may result in more delays. Please try again later.';
          } else {
            this.verifyFailMsg = 'Something went wrong. We\'re working on it! Please try again later.';
          }
        });
      } else {
        const verifyAttributeForm = new FormBuilder().group({
          verificationCode: [params.code, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
        });
        if (!verifyAttributeForm.valid) {
          this.router.navigate(['/invalid-verification']);
          return;
        }
        this.authService.verifyEmail(verifyAttributeForm.get('verificationCode').value).then(() => {
          this.launch.eventTrack('Verify Email - Success', { username: user.username, preferredUsername: user.attributes.preferred_username, sub: user.attributes.sub, customOwnerStatus: user.attributes['custom:owner_status'] });
          this.router.navigate(['/settings']);
        }).catch(err => {
          this.launch.eventTrack('Verify Email - Failed', { username: user.username, preferredUsername: user.attributes.preferred_username, sub: user.attributes.sub, customOwnerStatus: user.attributes['custom:owner_status'] });
          console.error(err);
          this.router.navigate(['/invalid-verification']);
        });
      }
    });
  }
}
