import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseMessageComponent } from '../../../utils/base-message.component';

@Component({
  selector: 'app-confirm-account-failed-message',
  templateUrl: './confirm-account-failed-message.component.html',
  styleUrls: ['./confirm-account-failed-message.component.scss']
})
export class ConfirmAccountFailedMessageComponent extends BaseMessageComponent implements OnInit {

  @Output() resend = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit() {}

  resendConfirmation() {
    this.startProcessing();
    this.resend.emit();
  }
}
