import {AbstractControl} from '@angular/forms';

export class CountryStateProvinceValidation {

  static SelectCountryStateProvince(form: AbstractControl) {
    const country = form.get('country').value;
    const stateProvince = form.get('stateProvince').value;
    if(country && stateProvince === '') {
      form.get('stateProvince').markAsDirty();
      form.get('stateProvince').setErrors({ SelectCountryStateProvince: true });      
    } else {
      return null;
    }
  }
}