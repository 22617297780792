import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss']
})
export class PasswordRequirementsComponent implements OnInit {

  @Input() passwordField: string;

  constructor() {}

  ngOnInit() {}

  allRequirementsMet() {
    this.containsForbidden();
    return this.min8() && this.containsLower() && this.containsUpper() && this.containsNumber()
      && this.containsSpecial();
  }

  min8() {
    return this.passwordField.length >= 8;
  }

  containsUpper() {
    return (/[A-Z]/.test(this.passwordField));
  }

  containsLower() {
    return (/[a-z]/.test(this.passwordField));
  }

  containsNumber() {
    return (/[0-9]/.test(this.passwordField));
  }

  containsSpecial() {
    // return (/[($!#&@%=_)]/.test(this.passwordField));
    return (/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/.test(this.passwordField));
  }
  containsForbidden(){
    return (/[^+=]/.test(this.passwordField));
  }
}
