import { Component, OnInit, Input, } from '@angular/core';

@Component({
  selector: 'app-contrast-bar',
  templateUrl: './contrast-bar.component.html',
  styleUrls: ['./contrast-bar.component.scss']
})
export class ContrastBar  implements OnInit {
    @Input() skipToContent:boolean = true;
    toggleContrast:boolean;
    bodyTag;
    skipText:string = "";
    ngOnInit(){
      this.bodyTag = document.querySelector('body');
      localStorage.getItem("highContrast") === "true" ? this.toggleSiteContrast(true) : this.toggleSiteContrast(false);
    }

    toggleSiteContrast(val:boolean){
      this.toggleContrast = val;
      this.toggleContrast ? this.bodyTag.classList.add('high-contrast-mode'): this.bodyTag.classList.remove('high-contrast-mode');
      localStorage.setItem("highContrast", val.toString());
    }
    skipToToggle(e:boolean){
      e === true ? this.skipText = "Skip to Content" :this.skipText ="";
    }
    goTo(location: string): void {
      window.location.hash = ''; 
      window.location.hash = location;
  }
}