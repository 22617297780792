import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FooterStates } from './footerStates.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  env:any;

  constructor() {
    this.env = environment;
  }
  year = (new Date()).getFullYear();
  @Input() vistanaLogoMod:Boolean = false;
 
  @ViewChild('firstNameFooter',{static:false,read: ElementRef}) firstNameFooter:ElementRef;
  @ViewChild('lastNameFooter',{static:false,read: ElementRef}) lastNameFooter:ElementRef;
  @ViewChild('emailFooter',{static:false,read: ElementRef}) emailFooter:ElementRef;
  @ViewChild('subscribeFooterCheckbox',{static:false,read: ElementRef}) subscribeFooterCheckbox:ElementRef;
  @ViewChild('subscribeFooterSubmit',{static:false,read: ElementRef}) subscribeFooterSubmit:ElementRef;
  @ViewChild('form',{static:false,read: ElementRef}) form:ElementRef;
  
  ngOnInit() {
  
  } 
  //Regex validators
  emailRegex =  new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)+$");
  nameRegex = new RegExp("^ ?[a-zA-ZÀ-úÀ-ÿ,.'-]+( [a-zA-ZÀ-úÀ-ÿ,.'-]+)* ?$");

  fnState:FooterStates={
    state:null,
    blank:null
  };
  lnState:FooterStates={
    state:null,
    blank:null
  };
  emailState:FooterStates={
    state:null,
    blank:null
  };
  cbxState:boolean;
  formState:boolean;
  processing:boolean;
  

  isFirstNameValid(){
    if(this.nameRegex.test(this.firstNameFooter.nativeElement.value) && this.firstNameFooter.nativeElement.value !== ""){
      this.fnState.state = true; 
      this.fnState.blank = false;          
    } else{
      this.fnState.state = false;
      this.fnState.blank = true;      
    }       
  }
  isLastNameValid(){
    if(this.nameRegex.test(this.lastNameFooter.nativeElement.value) && this.lastNameFooter.nativeElement.value !== ""){
      this.lnState.state = true;
      this.lnState.blank = false;      
    } else{
      this.lnState.state = false;  
      this.lnState.blank = true;    
    }    
  }
  isEmailValid(){
    if(this.emailRegex.test(this.emailFooter.nativeElement.value) && this.emailFooter.nativeElement.value !== ""){
      this.emailState.state = true;
      this.emailState.blank = false;     
    } else{
      this.emailState.state = false; 
      this.emailFooter.nativeElement.value === "" ? this.emailState.blank = true : this.emailState.blank = false;     
    }    
  } 
  isChecked(){
    this.subscribeFooterCheckbox.nativeElement.checked ? this.cbxState = true : this.cbxState = false;         
  }
  isFormValid(){
    this.processing = true;
    this.isFirstNameValid();
    this.isLastNameValid();
    this.isEmailValid();
    this.isChecked();       
    if(this.fnState.state && this.lnState.state && this.emailState.state && this.cbxState){
      this.form.nativeElement.submit();      
    }else{      
      this.formState = false;
      this.processing = false;
    }
    
  }
}
