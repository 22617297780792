import { Component, OnInit } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-hide-show-field',
  templateUrl: './hide-show-field.component.html',
  styleUrls: ['./hide-show-field.component.scss']
})
export class HideShowFieldComponent implements OnInit {
  show = false;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  constructor() { }

  ngOnInit() {}

  toggleShow() {
    this.show = !this.show;
  }
}
