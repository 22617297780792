import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})

export class GenericErrorComponent implements OnInit {

  title: string = 'WE\'RE SORRY.';

  constructor( private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Error');
    window.digitalData = {pageInfo: {}, userInfo: {}};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Error Page';
  }

}