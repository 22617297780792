import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { PhoneNumberValidator } from '../../utils/intl-phone-validation';
import { BaseFormComponent } from '../base-form.component';
import { Router } from '@angular/router';
import { VerificationStatusService } from '../../services/verification-status.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-user-form',
  templateUrl: './update-user-form.component.html',
  styleUrls: ['./update-user-form.component.scss']
})
export class UpdateUserFormComponent extends BaseFormComponent implements OnInit {
  showPhone = false;
  user: any;
  success = false;
  processingSub:boolean = false;
  initialFormState: FormGroup;
  @Input() currentEmail: string;
  @Input() currentPhone: string;
  @Input() emailVerified: boolean;
  @Input() phoneVerified: boolean;
  @Input() showVerificationContent: boolean;
  @Input() returnUrl: string;

  @Output() updateUser = new EventEmitter<FormGroup>();
  @Output() pageLoadingStatus =  new EventEmitter<boolean>();

  constructor(private authService: AuthenticationService, private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService.getCurrentUser(true).then(user => {
      this.user = user;
      this.setInitialFormValues();
      this.setPhoneValidator();
      this.pageLoadingStatus.emit(false);
    });
    
  }

  setInitialFormValues() {
    this.form.patchValue(
      {username: (this.user.attributes.preferred_username) ? this.user.attributes.preferred_username : this.user.username});
    this.form.patchValue({email: this.user.attributes.email});
    this.form.patchValue({phone: (this.user.attributes.phone_number as string)});

    if (this.user.attributes.phone_number) {
      this.showPhone = true;
      this.form.patchValue({phoneEnabled: true});
    } else {
      this.showPhone = false;
      this.form.patchValue({phoneEnabled: false});
    }
    this.form.setErrors(null);
    this.initialFormState = this.form.value;
  }

  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      username: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9][a-zA-Z0-9_.@]+$'), Validators.maxLength(64), Validators.minLength(4)])],
      email: [null, Validators.compose([Validators.required,
        Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      phoneEnabled: [false, Validators.compose([Validators.required])],
      phone: [null, [Validators.pattern('[0-9+]+'), PhoneNumberValidator()]],
    });
  }

  setPhoneValidator() {
    const phoneField = this.form.get('phone');
    this.form.get('phoneEnabled').valueChanges
      .subscribe(phoneEnabled => {
        if (phoneEnabled) {
          phoneField.setValidators([Validators.required, Validators.pattern('[0-9+]+'), PhoneNumberValidator()]);
        } else {
          phoneField.setValidators(null);
        }
        phoneField.updateValueAndValidity();
      });
  }

  cancelForm() {
    this.form.reset();
    this.form.setErrors(null);
    if(this.returnUrl === "vistana.dashboard") {
      window.location.href = environment.vistana_baseurl + '/dashboard';
    } else {
      this.router.navigate(['/settings/account-summary']);
    }
  }

  userUpdate() {
    this.form.setErrors(null);

    if(this.form.get('username').value === this.initialFormState['username'] && this.form.get('email').value === this.initialFormState['email'] && ((this.showPhone && (this.form.get('phone').value === this.initialFormState['phone'])) || (!this.showPhone && (this.form.get('phone').value === undefined || this.form.get('phone').value === null)))) {
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if(this.showPhone && this.user.attributes.email_verified === false && this.form.get('phone').value !== this.initialFormState['phone']) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.showPhone) {
      this.form.patchValue({phone: null});
    }

    if(this.form.get('email') !== this.initialFormState['email']) {
      this.form.get('email').markAsPristine();
      this.form.get('email').markAsUntouched();
      let email = this.form.get('email').value;
      this.form.get('email').setValue(email.toLowerCase());
    }

    if(this.form.get('phone') !== this.initialFormState['phone']) {
      this.form.get('phone').markAsPristine();
      this.form.get('phone').markAsUntouched();
    }
    
    this.startProcessing();
    this.initialFormState = this.form.value;
    this.updateUser.emit(this.form.value);
  }

  togglePhone() {
    this.showPhone = !this.showPhone;
  }
   // Eliminate spaces before or after a value. Accepts input name as argument.
   trimValue(val){
    const item = this.form.get(val);    
    if(item.value){item.setValue(item.value.trim())}      
  }
  //Toastr
  messageDisplay:boolean = false;

  toastrShowing(evt){  
    this.messageDisplay = evt;
  }
      
  messageData:any = {} ;
  
  messageDataUpdate = (outcome) =>{ 
    this.messageData = new Object;     
    this.messageData.type = outcome;
    if(outcome === 'success'){
      this.messageData.copy=['Successfully submitted.'];        
    } else if(outcome === 'error'){
      this.messageData.copy=['Account update failed. Please resubmit.'];
    }
    this.messageData.copy ? this.toastrShowing(true) : null;   
  }

}
