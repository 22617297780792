import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BaseFormComponent } from '../base-form.component';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-owner-lookup-form',
  templateUrl: './owner-lookup-form.component.html',
  styleUrls: ['./owner-lookup-form.component.scss']
})
export class OwnerLookupFormComponent extends BaseFormComponent {
  @Input() user: any;
  @Input() title: any;
  @Input() descriptionParagraph: any;
  @Input() parentComp: any;
  @Output() resetByContractEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() connectEvent: EventEmitter<any> = new EventEmitter<any>();


  registerAsCompany:boolean;
  useSSN:boolean;
  showAccountNotFound;
  showErrorMessage;
  emailPhoneNotVerified;
  resetMessage;
  accountNotAssociated;
  duplicateOwner;
  ownershipTaken: boolean;
  showSuccess;
  inactiveOwner;
  accountDisabled;
  codeDeliveryDetails;
  processingSub:boolean = false;


  @Output()
  lookup = new EventEmitter<FormGroup>();

  constructor(private authService: AuthenticationService, private router: Router, private launch: AdobeLaunchService, private cookie: CookieService) {
    super();
  }

  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      firstName: [null, Validators.compose([Validators.required, Validators.pattern("(?=\\w{1,25})^[^ ][\\w\\s,.-]*$")])],
      lastName: [null, Validators.compose([Validators.required, Validators.pattern("(?=\\w{1,25})^[^ ][\\w\\s,.-]*$")])],
      contract: [null, Validators.compose([Validators.required, Validators.pattern("[0-9]{6}$")])],
      propertyId: ["", Validators.compose([Validators.required, Validators.pattern("[0-9]{2}|all")])],

    });
    
  }

  resetAllErrors() {
    this.showAccountNotFound = false;
    this.showErrorMessage = false;
    this.emailPhoneNotVerified = false;
    this.accountNotAssociated = false;
    this.duplicateOwner = false;
    this.showSuccess = false;
    this.inactiveOwner = false;
    this.ownershipTaken = false;
  }

  lookupOwner() {
    this.processingSub = true;

    this.form.setErrors(null);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.startProcessing();
    this.lookup.emit(this.form.value);
    this.processingSub = false;
  }

  resetByContract() {
    this.processingSub = true;


    this.resetAllErrors();

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.processingSub = false;
      return;
    }

    if(this.form.value.propertyId === "") {
      this.form.value.propertyId = null;
    }
 
    this.authService.resetByContract(this.form.value).subscribe(async res => {      
      if(res == null ) {
        this.showSuccess = true;
        this.processingSub = false;
        this.resetMessage = "Password successfully reset!";
        this.resetByContractEvent.emit(this);
      } else {
        if(res['accountDisabled']) {
          this.accountDisabled = res['accountDisabled'];
          this.launch.eventTrack('Password Reset by Contract - Failed - Account Disabled', {});
        }
        
        if(res['accountNotAssociated']) {
          this.accountNotAssociated = res['accountNotAssociated'];
          this.launch.eventTrack('Password Reset by Contract - Failed - Account Not Associated', {});
        }

        if(res['emailPhoneNotVerified'] || res['emailPhoneNotPresent']) {
          this.emailPhoneNotVerified = res['emailPhoneNotVerified'] || res['emailPhoneNotPresent'];
          this.launch.eventTrack('Password Reset by Contract - Failed - No Verified Communication', {});
        }        
        this.resetMessage = res['message'];
        this.showSuccess = res['sendSuccess'];
        this.showErrorMessage = !res['sendSuccess'];
        this.codeDeliveryDetails =res['codeDeliveryDetailsType'];
        this.resetByContractEvent.emit(this);
        
        this.processingSub = false;
      }
    }, error => {
      this.processingSub = false;      
      this.launch.eventTrack('Password Reset by Contract - Failed - General Error', {});
      //Handle owner services down error
      if(error.error.status === 400 && error.error.message === "Expected information was not present on the response." && error.error.title === "UPDATE-FAILED"){
        this.router.navigate(['error'])
      } else{
        this.resetMessage = "An unexpected error has occurred. Please try again."
        this.showErrorMessage = true;
        this.resetByContractEvent.emit(this);
        
        }
    });
    
}

  connectOwner() {
    this.processingSub = true;


    this.resetAllErrors();

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.processingSub = false;
      return;
    }

    if(this.form.value.propertyId === "") {
      this.form.value.propertyId = null;
    }

    this.authService.connectOwnership(this.user, this.form.value).subscribe(async res =>{ 
      await this.authService.getCurrentUser(true).then(user => {
        this.user = user
        this.launch.eventTrack('Connect Ownership - Success', { username: user.username, preferredUsername: user.attributes.preferred_username, sub: user.attributes.sub, customOwnerStatus: user.attributes['custom:owner_status'] });
        
      });
      
      document.querySelector('body').classList.add('modal-open');
      this.cookie.set('connectComplete','true',1, '/');
      this.authService.clearSessionAlt(`${window.location.origin}/settings/account-summary`);
    }, error => {
      this.processingSub = false;
      if(error.status >=400 && error.status < 500) {
        if(error.error[0].error == 'UNIQUE_VALUE_EXISTS') {
          this.duplicateOwner = true;
          this.launch.eventTrack('Connect Ownership - Failed - Already Connected', { username: this.user.username, preferredUsername: this.user.attributes.preferred_username, sub: this.user.attributes.sub, customOwnerStatus: this.user.attributes['custom:owner_status'] });
          this.connectEvent.emit(this);
        } else if (error.error[0].error === 'OWNERSHIP_TAKEN') {
          this.ownershipTaken = true;
          this.launch.eventTrack('Connect Ownership - Failed - Ownership Taken', { username: this.user.username, preferredUsername: this.user.attributes.preferred_username, sub: this.user.attributes.sub, customOwnerStatus: this.user.attributes['custom:owner_status'] });

          this.connectEvent.emit(this);
        } else if(error.error[0].error == 'OWNERSHIP_INACTIVE') {
          this.inactiveOwner = true;
          this.launch.eventTrack('Connect Ownership - Failed - Ownership Inactive', { username: this.user.username, preferredUsername: this.user.attributes.preferred_username, sub: this.user.attributes.sub, customOwnerStatus: this.user.attributes['custom:owner_status'] });
          this.connectEvent.emit(this);
        } else {
          this.showAccountNotFound = true;
          this.launch.eventTrack('Connect Ownership - Failed - Ownership Not Found', { username: this.user.username, preferredUsername: this.user.attributes.preferred_username, sub: this.user.attributes.sub, customOwnerStatus: this.user.attributes['custom:owner_status'] });
          this.connectEvent.emit(this);
        } 
      } else if(error.status >=500) {
        this.showErrorMessage = true;
        this.connectEvent.emit(this);       
        this.launch.eventTrack('Connect Ownership - Failed - General Error', { username: this.user.username, preferredUsername: this.user.attributes.preferred_username, sub: this.user.attributes.sub, customOwnerStatus: this.user.attributes['custom:owner_status'] });
      }          
    });     
  }

  registerAsCompanyCheckbox(evt) {
    if(evt === true) {
      this.form.addControl('corpName', new FormControl('', Validators.required));
      this.form.removeControl('firstName');
      this.form.removeControl('lastName');
      this.form.updateValueAndValidity();

    } else {
      this.form.addControl('firstName', new FormControl('', [Validators.required, Validators.pattern("(?=\\w{1,25})^[^ ][\\w\\s,.-]*$")]));
      this.form.addControl('lastName', new FormControl('', [Validators.required, Validators.pattern("(?=\\w{1,25})^[^ ][\\w\\s,.-]*$")]));
      this.form.removeControl('corpName');
      this.form.updateValueAndValidity();

    }
  }

  useSSNCheckbox(evt) {
    if(evt === true) {
      this.useSSN = true;
      this.form.addControl('smallSocSecNum', new FormControl('', [Validators.required, Validators.pattern("[0-9]{4}")]));
      this.form.removeControl('propertyId');
      this.form.removeControl('contract');
      this.form.updateValueAndValidity();
 
    } else {
      this.useSSN = false;
      this.form.addControl('propertyId', new FormControl('', [Validators.required, Validators.pattern("[0-9]{2}|all")]));
      this.form.addControl('contract', new FormControl('', [Validators.required, Validators.pattern("[0-9]{6}$")]));
      this.form.removeControl('smallSocSecNum');
      this.form.updateValueAndValidity();

    }
  }
  
  openNameHelp(url, target) {
    window.open(url, target)

  }
}
