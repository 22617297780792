import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForcedUpdatePasswordFormComponent } from '../../forms/forced-update-password-form/forced-update-password-form.component';
import { LoginFormComponent } from '../../forms/login-form/login-form.component';
import { DOCUMENT } from '@angular/common';
import { SsoService } from '../../services/sso.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { ConfirmResentMessageComponent } from '../confirm-account/confirm-resent-message/confirm-resent-message.component';
import { ConfirmRequiredMessageComponent } from '../confirm-account/confirm-required-message/confirm-required-message.component';
import { PasswordResetRequiredMessageComponent } from '../reset/password-reset-required-message/password-reset-required-message.component';
import { PasswordResetSentMessageComponent } from '../reset/password-reset-sent-message/password-reset-sent-message.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('loginForm', {static: false}) public loginForm: LoginFormComponent;
  @ViewChild('updateForm', {static: false}) public updateForm: ForcedUpdatePasswordFormComponent;

  @ViewChild('confirmResent', { static: false }) public confirmResent: ConfirmResentMessageComponent;
  @ViewChild('confirmRequired', { static: false }) public confirmRequired: ConfirmRequiredMessageComponent;

  @ViewChild('resetResent', { static: false }) public resetResent: PasswordResetSentMessageComponent;
  @ViewChild('resetRequired', { static: false }) public resetRequired: PasswordResetRequiredMessageComponent;

  user: any;
  username: string;
  currentUsername: string;
  currentPassword: string;
  state: string;
  deliveryDetails: any;
  browserIEorFF:boolean;
  env: any;


  constructor(@Inject(DOCUMENT) private document: any, private route: ActivatedRoute, private router: Router,
              private ssoService: SsoService, private authService: AuthenticationService, private launch: AdobeLaunchService) {
            this.env = environment;
  }

  ngOnInit() {
    window.digitalData = {pageInfo: {}, userInfo: {}};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Account Login';
    window.digitalData.userInfo = {};
    window.navigator.userAgent.includes('Trident/') || window.navigator.userAgent.includes('Firefox/') ? this.browserIEorFF = true : this.browserIEorFF = false;
  }

  async login(event: any) {
    try {
      window.digitalData.userInfo = {userName: event.username};
      //Username formatted here so that it is imperceptible to user
      const user = await this.authService.auth(event.username.trim().replace(/ /g,'_'), event.password);
      this.launch.eventTrack('Login - Successful', {username: user.username, preferredUsername: user.attributes.preferred_username, sub: user.attributes.sub, customOwnerStatus: user.attributes['custom:owner_status']});

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.user = user;
        this.currentUsername = event.username;
        this.currentPassword = event.password;
        this.state = 'update';
        window.location.hash = 'update';
      } else {
        this.redirectToEndOfFlow();
      }
    } catch (err) {
      this.loginForm.processingSub = false;
      console.error(err);
      if (err.code === 'UserNotConfirmedException') {
        this.launch.eventTrack('Login - Failed - Account Confirmation Required', {username: event.username});
        this.state = 'confirm-account';
        this.currentUsername = event.username;
        window.location.hash = 'confirm-account';
      } else if (err.code === 'PasswordResetRequiredException') {
        this.launch.eventTrack('Login - Failed - Reset Required', {username: event.username});
        this.state = 'reset';
        this.currentUsername = event.username;
        window.location.hash = 'reset';
      } else if (err.message === 'User is disabled.') {
        this.launch.eventTrack('Login - Failed - Account Disabled', {username: event.username});
        this.loginForm.processingComplete();
        this.loginForm.form.setErrors({accountDisabled: true});
        this.loginForm.error = "Your account is no longer active. For assistance please email or contact Owner Services.";
      } else if (err.code === 'TooManyFailedAttemptsException'
          || err.code === 'TooManyRequestsException'
          || err.message === 'Password attempts exceeded') {
        this.launch.eventTrack('Login - Failed - Throttled', {username: event.username});
        this.loginForm.processingComplete();
        this.loginForm.form.setErrors({throttledAttempt: true});
        this.loginForm.error = "You have made multiple incorrect login attempts. Please try again later. More incorrect attempts will result in more delays.";
      } else {
        this.launch.eventTrack('Login - Failed', {username: event.username});
        this.loginForm.processingComplete();
        this.loginForm.form.setErrors({invalidLogin: true});
        this.loginForm.error = "Please verify your login information and try again.";
      }
    }
  }

  redirectToEndOfFlow() {
    const params = this.route.snapshot.queryParams;
    const redirectUri = decodeURIComponent(params.redirect_uri);
    const stateParam = params.state;
    const returnUrl = params.returnUrl;

    try {
      if (redirectUri && this.ssoService.isSpValid(redirectUri)) {
        this.router.navigate(['/authorize'], {queryParams: {redirect_uri: redirectUri, state: stateParam}});
      } else if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      } else {
        window.location.href = environment.vistana_baseurl + '/dashboard';
      }
    } catch (err) {
      this.router.navigate(['/settings/account-summary']);
    }
  }

  @HostListener('window:hashchange')
  update() {
    const hash = (window.location.hash).toLowerCase();
    const tab: string = (hash).substring(1, hash.length);
    if (tab !== this.state) {
      this.state = null;
    }
  }

  async forcedUpdatePassword(event: any) {
    try {
      await this.authService.forcedUpdatePassword(this.user, event.password);
      this.redirectToEndOfFlow();
    } catch (err) {
      this.updateForm.processingComplete();
      console.error(err);
      this.updateForm.warnings = {generalError: true};
      this.updateForm.processingSub = false;
    }
  }

  async resendVerification() {
    try {
      let usernameVal = this.currentUsername.trim().replace(/ /g,'_');
      this.launch.eventTrack('Login - Request Confirmation Link', {username: usernameVal});
      this.deliveryDetails = (await this.authService.resendVerifyAccount(usernameVal)).CodeDeliveryDetails;
      if (this.confirmRequired) { this.confirmRequired.processingComplete(); }
      if (this.confirmResent) { this.confirmResent.processingComplete(); }
    } catch (err) {
      if (this.confirmRequired) { this.confirmRequired.processingComplete(); }
      if (this.confirmResent) { this.confirmResent.processingComplete(); }
      window.location.hash = '';
      console.error(err);
    }
  }
  unconfirmed:boolean = false;
  async sendPasswordReset() {
    try {
      let usernameVal = this.currentUsername.trim().replace(/ /g,'_');
      this.deliveryDetails = (await this.authService.reset(usernameVal)).CodeDeliveryDetails;
      if (this.resetRequired) {
        this.launch.eventTrack('Login - Failed - Reset Required - Request Password Reset Link', {username: usernameVal});
        this.resetRequired.processingComplete();
      }
      if (this.resetResent) {
        this.launch.eventTrack('Login - Failed - Reset Required - Resend Password Reset Link', {username: usernameVal});
        this.resetResent.processingComplete();
      }
    } catch (err) {
      if (this.resetRequired) { this.resetRequired.processingComplete(); }
      if (this.resetResent) { this.resetResent.processingComplete(); }
      if(err.message === "Cannot reset password for the user as there is no registered/verified email or phone_number"){
        this.unconfirmed = true;
      } else{
        window.location.hash = '';
      }

      console.error(err);
    }
  }
  //Reset state to allow for navigation back to normal login screen with login form
  resetStateNavigate(event: any){
    if(event) {
      this.launch.eventTrack(event, {username: this.currentUsername.trim().replace(/ /g,'_')});
    }
    this.deliveryDetails = null;
    window.location.hash = '';
    this.update();
    this.router.navigate(['/']);
  }
}
