import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmAccountFormComponent } from '../../forms/confirm-account-form/confirm-account-form.component';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { ConfirmAccountFailedMessageComponent } from './confirm-account-failed-message/confirm-account-failed-message.component';
import { ConfirmResentMessageComponent } from './confirm-resent-message/confirm-resent-message.component';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {

  @ViewChild('confirmFailed', { static: false }) public confirmFailed: ConfirmAccountFailedMessageComponent;
  @ViewChild('confirmResent', { static: false }) public confirmResent: ConfirmResentMessageComponent;

  @ViewChild('verifyAttributeForm', {static: true}) private confirmAccountForm: ConfirmAccountFormComponent;
  public username: string;
  public code: string;
  public success;
  public deliveryDetails: any;
  title:string = "Account Confirmation";

  constructor(private route: ActivatedRoute, private router: Router,
              private authService: AuthenticationService, private launch: AdobeLaunchService) {
  }

  ngOnInit() {
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Confirm Account';

    const params = this.route.snapshot.queryParams;
    if (!params.username) {
      this.router.navigate(['/login']);
      return;
    }

    this.username = params.username;
    this.code = params.code;

    if (this.code && this.username) {
      this.authService.verifyAccount(this.username, this.code).then(() => {
        this.success = true;
        this.launch.eventTrack('Account Verification - Success', {username: this.username});
      }).catch( err => {
        console.error(err);
        if (err.message === 'User is already confirmed' || err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
          this.launch.eventTrack('Account Verification - Already Confirmed', {username: this.username});
          this.success = true;
        } else {
          this.launch.eventTrack('Account Verification - Failed', {username: this.username});
          this.success = false;
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async resendConfirmation() {
    try {
      this.launch.eventTrack('Account Verification - Confirmation Resent', {username: this.username});
      this.deliveryDetails = (await this.authService.resendVerifyAccount(this.username)).CodeDeliveryDetails;
      if (this.confirmFailed) { this.confirmFailed.processingComplete(); }
      if (this.confirmResent) { this.confirmResent.processingComplete(); }
    } catch (err) {
      console.error(err);
      this.router.navigate(['/login']);
    }
  }
}
