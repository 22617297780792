import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'ja',
      name: '日本語'
    }
  ];

  constructor() {}

  findLanguage(locale) {
    for (const e of this.languages) {
      if (e.code === locale) {
        return e;
      }
    }

    return this.languages[0];
  }

  determineLanguage() {
    if (window.location.pathname.split('/').length > 1) {
      const lang = window.location.pathname.split('/')[1];
      return this.findLanguage(lang);
    }
  }
}
