import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SsoService } from '../../services/sso.service';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,
              public authService: AuthenticationService, private ssoService: SsoService,
              private launch: AdobeLaunchService) {
  }

  ngOnInit() {
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Account Logout';
    window.digitalData.pageInfo = {};
    const params = this.route.snapshot.queryParams;
    this.authService.logout().then(() => {
      if (this.ssoService.isSpValidLogout(params.logout_uri)) {
        window.location.href = params.logout_uri;
      } else {
        window.location.href = environment.idp_baseurl + '/sso/logout';
      }
    });
  }
}
