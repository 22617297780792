import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent  implements OnInit {
    //This component will expect an object through @Input that contains headline copy, 
    //sub headline copy, message copy, button copy, and button link/function
    //TODO: Create model for incoming Object
    @Input() modals
    @Input() clearParams:Boolean;
    @Output() closeModal = new EventEmitter<boolean>();
    @ViewChild('closeBtn',{static:false}) closeBtn: ElementRef;

    bodyEl = document.getElementsByTagName('body');
    modalShows = false;
    ctaOnlyClose = false;  
    constructor(private route: ActivatedRoute, private router: Router){
      
    }

    ngOnInit(){ 
      this.modalShows = this.modals.modalShows;
      if(this.modals.modalShows){
          setTimeout(()=>{this.closeBtn.nativeElement.focus();},100)
        } 
    }
  
    hideModal(){
      if(!this.modals.ctaOnlyClose){        
        //Clear query strings
        if(this.clearParams){
          this.clearQueryString()
          this.closeModal.emit(false);
        }else{
          this.closeModal.emit(false);
        }
      } 
    }

    clearQueryString(){
      this.router.navigate([],
        {
        queryParams:{connected: null},          
        queryParamsHandling: 'merge'
      },
      );
    }
    
    actionLog(evt){
      evt.stopPropagation();          
    }
}