import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdobeLaunchService } from '../../services/adobe-launch.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  title="Page not Found";
  constructor(public router: Router, private launch: AdobeLaunchService) {}

  ngOnInit() {
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | 404 Page';
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 3000);
  }
}
