import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class VerificationStatusService {
  private unverifiedCount = new BehaviorSubject<number>(0);
  private emailVerified = new BehaviorSubject<boolean>(false);
  private email = new BehaviorSubject<string>('');
  private phoneVerified = new BehaviorSubject<boolean>(false);
  private phone = new BehaviorSubject<string>('');
  currentCount = this.unverifiedCount.asObservable();
  emailVerificationStatus = this.emailVerified.asObservable();
  currentEmail = this.email.asObservable();
  phoneVerificationStatus = this.phoneVerified.asObservable();
  currentPhone = this.phone.asObservable();

  constructor() { }

  updateUnverifiedCount(count: number) {  
    this.unverifiedCount.next(count);
  }

  updateEmailVerificationStatus(verified: boolean, email: string) {    
    this.email.next(email);
    this.emailVerified.next(verified);
  }

  updatePhoneVerificationStatus(verified: boolean, phone: string) {   
    this.phone.next(phone);
    this.phoneVerified.next(verified);
  }
}