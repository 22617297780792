import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { BaseFormComponent } from '../base-form.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent extends BaseFormComponent {
  contactUs: string;
  processingSub:boolean = false;

  @Output()
  login = new EventEmitter<FormGroup>();

  constructor(private titleService: Title) {
    super();
    this.contactUs = environment.contactUs;
  }
 // Eliminate spaces before or after a value. Accepts input name as argument.
  trimValue(val){
    const item = this.form.get(val);    
    if(item.value){item.setValue(item.value.trim())}      
  }
  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });
  }

  loginUser() {
    this.form.setErrors(null);    
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.startProcessing();
    this.login.emit(this.form.value);
    this.processingSub = true;
  }
  ngAfterViewInit(): void {
    //Title service must be called from this lifecycle hook to not interfere with form functions
    this.titleService.setTitle('Login | Vistana Signature Experience');
  }
    

  // Import from legacy
    error:any;
    genWarning = false;
}
