import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ACCEPT_REGIONS } from '../../globals';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }

    let validNumber = false;
    try {
      const region = phoneNumberUtil.getRegionCodeForNumber(phoneNumberUtil.parse(control.value));
      if (ACCEPT_REGIONS.includes(region)) {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, region);
        validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      }
    } catch (e) {}

    return validNumber ? null : { invalidPhone: { value: control.value } };
  };
}
