import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../../utils/password-validation';
import { PasswordRequirementsComponent } from '../../utils/password-requirements/password-requirements.component';
import { PhoneNumberValidator } from '../../utils/intl-phone-validation';
import { BaseFormComponent } from '../base-form.component';
import { StatesCountries} from '../../services/states-countries-service';
import { Location } from '@angular/common';
import { CommsValidation } from '../../utils/comm-options-validation';
import { AddressFieldsValidation } from '../../utils/address-fields-sequence-validation';
import { EmailMatchValidation } from '../../utils/email-match-validation';
import { Title } from '@angular/platform-browser';
import { CountryStateProvinceValidation } from '../../utils/country-state-province-validation';
import { PasswordReqsValidation } from '../../utils/password-reqs-validation';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent extends BaseFormComponent implements OnInit {
  @ViewChild('passwordReqComponent', { static: true }) private passwordReqComponent: PasswordRequirementsComponent;
  showPhone = false;
  showPwHelp = true;

  constructor(private statesCountries: StatesCountries, private location: Location, private titleService: Title, ) {
    super();
  }


  @Output()
  create = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.titleService.setTitle('Account Registration | Vistana Signature Experience');
    super.ngOnInit();
    this.setPhoneValidator();
    this.form.get('textOptIn').disable();
    this.statesCountries.getLocationsGuest().subscribe(data =>{
      this.countriesAndProvinces = data;
    })
    this.error = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    //Enable or disable text msg opt in based on phone field state
    this.form.get('phone').valueChanges.subscribe(e => {
      this.form.get('phone').touched && (this.form.get('phone').value !== '' && this.form.get('phone').value !== null) ? this.form.get('textOptIn').enable() : [this.textOptIn.nativeElement.checked = false,this.form.get('textOptIn').disable()];
    });
  }

  reggie = '[^=+]';
  processingSub:boolean = false;
  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      firstname: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9\-., ]*$')])],
      lastname: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9\-., ]*$')])],
      username: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9][a-zA-Z0-9_.@]*$')])],
      email: [null, Validators.compose([Validators.required,
        Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      confirmEmail: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
      address1: [null,Validators.pattern('^[a-zA-Z0-9\-.,#/&\' ]*$') ],
      address2:[null,Validators.pattern('^[a-zA-Z0-9\-.,#/&\' ]*$') ],
      address3:[null,Validators.pattern('^[a-zA-Z0-9\-.,#/&\' ]*$') ],
      city: [null, Validators.pattern('^[a-zA-Z0-9\-,. ]*$')],
      postal: [null, Validators.pattern('^[a-zA-Z0-9\-, ]*$')],
      country: ["", ],
      stateProvince: ["", ],
      phoneEnabled: [false, Validators.compose([Validators.required])],
      phone: [null, [Validators.pattern('[0-9+]+'), PhoneNumberValidator()]],
      textOptIn: [null,{disabled:true}, {checked: ''} ],
      newsletters:[true,Validators.compose([])],
      promotions:[true,Validators.compose([])],
      specials: [true,Validators.compose([])],
      optOutComms: [null,Validators.compose([])],
    }, {
      validator: [PasswordValidation.MatchPassword, CommsValidation.SelectComms, AddressFieldsValidation.AddressFieldsSequence, EmailMatchValidation.MatchEmail, CountryStateProvinceValidation.SelectCountryStateProvince, PasswordReqsValidation.PasswordReqsValidation]
    });
  }
  // Eliminate spaces before or after a value. Accepts input name as argument.
  trimValue(val){
      const item = this.form.get(val);
      if(item.value){item.setValue(item.value.trim())}
  }
  inputState(){
    this.form.get('phone').touched && (this.form.get('phone').value !== '' && this.form.get('phone').value !== null)? this.form.get('phone').patchValue({diabled: false}) : this.form.get('phone').patchValue({diabled: true})
  }
  setPhoneValidator() {
    const phoneField = this.form.get('phone');
    this.form.get('phoneEnabled').valueChanges
      .subscribe(phoneEnabled => {
        if (phoneEnabled) {
          this.form.get('phone').patchValue('+1');
          phoneField.setValidators([Validators.required, Validators.pattern('[0-9+]+'), PhoneNumberValidator()]);
        } else {
          phoneField.setValue(null);
          phoneField.setValidators(null);
        }
        phoneField.updateValueAndValidity();
      });
  }

  register() {

    this.form.setErrors(null);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.checkForErrors();
      return;
    }
    this.startProcessing();
    this.create.emit(this.form.value);
  }

  togglePhone() {
    this.showPhone = !this.showPhone;
  }

  //****Carryover functions from legacy*****//
  // TODO: Replace show icon image with one from API
  viewPwToggle = false;

  pwImg = 'assets/pw-hide.png';

  countriesAndProvinces;
  countrySelected = {
    stateProvinces: false
  };


  error: boolean;

  onlineStatus = true;

  @ViewChild('firstname', {static:true, read: ElementRef }) firstname:ElementRef;
  @ViewChild('lastname', { static: true,read: ElementRef }) lastname:ElementRef;
  @ViewChild('username', { static: true, read: ElementRef }) username:ElementRef;
  @ViewChild('address1', { static: true, read: ElementRef }) address1:ElementRef;
  @ViewChild('address2', { static: true, read: ElementRef }) address2:ElementRef;
  @ViewChild('address3', { static: true, read: ElementRef }) address3:ElementRef;
  @ViewChild('city', { static: true, read: ElementRef }) city:ElementRef;
  @ViewChild('postal', { static: true, read: ElementRef }) postal:ElementRef;
  @ViewChild('countryList', { static: true, read: ElementRef }) countryList:ElementRef;
  @ViewChild('stateProvince', { static: true, read: ElementRef }) stateProvince:ElementRef;
  @ViewChild('email', { static: true, read: ElementRef }) email:ElementRef;
  @ViewChild('confirmEmail', { static: true, read: ElementRef }) confirmEmail:ElementRef;
  @ViewChild('password', { static: true, read: ElementRef }) password:ElementRef;
  @ViewChild('confirmPassword', { static: true, read: ElementRef }) confirmPassword:ElementRef;
  @ViewChild('phone', { static: true, read: ElementRef }) phone:ElementRef;
  @ViewChild('textOptIn', { static: true, read: ElementRef }) textOptIn:ElementRef;
  @ViewChild('newsletters', { static: true, read: ElementRef }) newsletters:ElementRef;
  @ViewChild('promotions', { static: true, read: ElementRef }) promotions:ElementRef;
  @ViewChild('specials', { static: true, read: ElementRef }) specials:ElementRef;
  @ViewChild('optOutComms', { static: true, read: ElementRef }) optOutComms:ElementRef;
  //Sets the value of the array for the state/city dropdown
  //***TODO: Move State and Province functions to service?
  //   And set default value on dropdown
  countrySelection($event: Event){
    let country = this.form.get('country').value;
    this.countrySelected = {
      stateProvinces: false
    };
    let countryArr = Object.values(this.countriesAndProvinces);
    countryArr.forEach((countryItem:any) =>{
      if(countryItem.countryCode === country){
        this.countrySelected = countryItem;
      }
    })
    if(this.countrySelected['stateProvinces']){
      this.form.get('stateProvince').setValue('');
    } else {
      this.form.get('stateProvince').setValue('N/A');
    }
  }

  goBack(){
    this.location.back();
  }

  //If comm type opted into, uncheck opt out of all comms
  unsetOptOut(evt){
    if(evt.target.checked === true){
      this.form.patchValue({optOutComms: false});
    }
  }
  //Sets all comm types except text to opt out
  optOutAll(evt){
    this.form.patchValue({
      newsletters: false,
      promotions: false,
      specials : false
    });
  }
  //Check which field/value is invalid
  checkForErrors(){
    const invalid = [];
      let firstError ;
      const controls = this.form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
      }
      firstError = eval("this."+invalid[0]);
     this.scrollTo(document.body, firstError.nativeElement.offsetTop, 600);
      this.error = true;
  }
    //Bring error field into view
    ACCEPT_REGIONS: any;
    scrollTo(element:any, to:number, duration:number) {
      if (duration <= 0) return;
      var difference = to - element.scrollTop;
      var perTick = difference / duration * 10;

      setTimeout(function() {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          this.scrollTo(element, to, duration-10);
      }, 10);
  }

}
