import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { parse } from 'path';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})

export class AccountSettingsComponent implements OnInit {

  footer: SafeHtml;
  title:string = 'Account Settings'; 
  user: any;
  userAttributes; 
  connectedAccountShow: false;
  isOwner:boolean;
  bodyEl = document.getElementsByTagName('body');  
  
  
  constructor(private authService: AuthenticationService, public sanitizer: DomSanitizer, private router: Router) {    
  }

  dcOwnershipModal = {   
      modalShows : false,
      ctaOnlyClose : false,  
      headline: "Are you sure?",    
      messageCopy:"By disconnecting your ownership account, you will no longer have access to view your ownership information online or view upcoming stays, pay maintenance fees or reserve online.",
      buttons:[
        {
          copy: "Keep Account Connected",
          btnFunction:(evt)=>{
            //Must call evt.stopPropagation();
            evt.stopPropagation();
            this.dcOwnershipModal.modalShows = false;
          },
          isCancelBtn: true
        },
        {
          copy: "Disconnect Account",
          btnFunction:async (evt)=>{
            //Must call evt.stopPropagation();
            evt.stopPropagation();
            this.authService.disconnectOwnership(this.user).subscribe(async res =>{ 
              await this.authService.getCurrentUser(true).then(user=>{this.user = user});
              this.authService.clearSessionAlt(this.router.url);
              this.router.navigate(['/welcome']);
              document.querySelector('body').classList.remove('modal-open')
            }, error => {
              console.error(error);              
            });            
          }
        }
      ]
    }
   whyModal = {   
      modalShows : false,
      ctaOnlyClose : false,  
      headline: "Why Connect?",    
      messageCopy:"By connecting your ownership account with your Vistana online account, you can make online reservations, view upcoming stays, access your financial and Owners Association information and more.",
      buttons:[
        {
          copy: "Connect Now",
          btnFunction:(evt)=>{
            //Must call evt.stopPropagation();
            evt.stopPropagation();
            this.whyModal.modalShows = false            
            this.router.navigate(['/settings/ownership'])
          }
        },        
      ]
    }
  
  async ngOnInit() {
    this.user = await this.authService.getCurrentUser(true);
    
    if(this.user){ 
        //Sets owner status for view
        this.userAttributes = this.user.attributes;       
        for (var key in this.userAttributes) {
          if (this.userAttributes.hasOwnProperty(key)) {             
              if(key === "custom:owner_status" ){
                this.userAttributes[key] === "1" ? this.isOwner = true : this.isOwner = false; 
              }
          }
      }
    }    
  }
  dcOwnershipModalShow(evt){
    this.dcOwnershipModal.modalShows = evt;
    evt ? this.bodyEl[0].classList.add('modal-open') : this.bodyEl[0].classList.remove('modal-open')
  }
  whyModalShow(evt){
    this.whyModal.modalShows = evt;
    evt ? this.bodyEl[0].classList.add('modal-open') : this.bodyEl[0].classList.remove('modal-open')
  }
   
}
