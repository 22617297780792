import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor() {}

  isSpValid(redirectUri: string) {
    for (let i = 0; i < environment.sso_issuers.length; i++) {
      if (redirectUri === environment.sso_issuers[i].redirect_uri) {
        return true;
      }
    }
    return false;
  }

  isSpValidLogout(logoutUri: string) {
    for (let i = 0; i < environment.sso_issuers.length; i++) {
      if (logoutUri === environment.sso_issuers[i].logout_uri) {
        return true;
      }
    }
    return false;
  }

  getServicingSiteHomePage() {
    return environment.sso_issuers[0].homepage_uri;
  }

  getServicingSiteLogout() {
    return environment.sso_issuers[0].logout_endpoint;
  }
}
