import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeFormComponent } from '../../forms/challenge-form/challenge-form.component';
import { AuthenticationService } from '../../services/authentication.service';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { ChallengeFailedMessageComponent } from './challenge-failed-message/challenge-failed-message.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss']
})
export class ChallengeComponent implements OnInit {

  @ViewChild('challengeForm', { static: false }) public challenge: ChallengeFormComponent;
  @ViewChild('challengeFailedMessage', { static: false }) public challengeFailed: ChallengeFailedMessageComponent;
  validCode = true;
  browserIEorFF: boolean;
  

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthenticationService,
              private launch: AdobeLaunchService, private cookie:CookieService) {}

  ngOnInit() {
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Password Reset Challenge';

    const currentTime = Math.floor((new Date()).getTime() / 1000);
    const iat = this.route.snapshot.queryParams.iat;

    if (iat && ((currentTime - iat) > 3600)) {
      this.launch.eventTrack('Password Reset Challenge - Code Expired/Mismatch',
        {username: this.route.snapshot.params.username});
      this.validCode = false;
    }
    window.navigator.userAgent.includes('Trident/') || window.navigator.userAgent.includes('Firefox/') ? this.browserIEorFF = true : this.browserIEorFF = false;
  }
  ngAfterViewInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  async confirmReset(event: any) {    
    try {
      await this.authService.confirmReset(event.username, event.verificationCode, event.password);
      this.launch.eventTrack('Password Reset Challenge - Success', {username: event.username});
      await this.authService.auth(event.username, event.password);
      this.cookie.set('resetComplete','true',1, '/');
      this.router.navigate(['/login']);
    } catch (err) {
      this.challenge.processingSub = false;
      console.error(err); 
      if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException' || err.message === 'ExpiredCodeException') {
        this.launch.eventTrack('Password Reset Challenge - Code Expired/Mismatch', {username: event.username});
        this.validCode = false;
      } else if (err.code === 'UserNotFoundException') {
        this.router.navigate(['/login']);
      } else if(err.code === 'NotAuthorizedException'){
        this.challenge.errForToastr = 'Your account is no longer active. For assistance please email or contact Owner Services.';
      } else {
        this.launch.eventTrack('Password Reset Challenge - General Error', {username: event.username});
        this.challenge.processingComplete();
        this.challenge.warnings = {generalError: true};
      }
      this.challenge.messageDataUpdate('error');
    }
  }

  async resendReset() {
    try {
      const username = this.route.snapshot.queryParams.username;
      await this.authService.reset(username);
      this.launch.eventTrack('Password Reset Challenge - Challenge Resent', {username});
      this.router.navigate(['/login']);
    } catch (err) {
      console.error(err);
      this.router.navigate(['/reset']);
    }
  }
}
