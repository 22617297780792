import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LoginComponent } from './authentication/pages/login/login.component';
import { LoginFormComponent } from './authentication/forms/login-form/login-form.component';
import { ResetComponent } from './authentication/pages/reset/reset.component';
import { RegistrationComponent } from './authentication/pages/registration/registration.component';
import { ManageAccountComponent } from './authentication/pages/manage-account/manage-account.component';
import { LogoutComponent } from './authentication/pages/logout/logout.component';
import { OwnerLookupFormComponent } from './authentication/forms/owner-lookup-form/owner-lookup-form.component';
import { RegisterFormComponent } from './authentication/forms/register-form/register-form.component';
import { ResetFormComponent } from './authentication/forms/reset-form/reset-form.component';
import { ChallengeFormComponent } from './authentication/forms/challenge-form/challenge-form.component';
import { ChallengeComponent } from './authentication/pages/challenge/challenge.component';
import { ForcedUpdatePasswordFormComponent } from './authentication/forms/forced-update-password-form/forced-update-password-form.component';
import { AuthorizeComponent } from './authentication/pages/authorize/authorize.component';
import { ConnectOwnershipComponent } from './authentication/pages/connect-ownership/connect-ownership.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmAccountComponent } from './authentication/pages/confirm-account/confirm-account.component';
import { ConfirmAccountFormComponent } from './authentication/forms/confirm-account-form/confirm-account-form.component';
import { HttpClientModule } from '@angular/common/http';
import { VerifyEmailComponent } from './authentication/pages/verify-email/verify-email.component';
import { VerifyPhoneComponent } from './authentication/pages/verify-phone/verify-phone.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { I18nComponent } from './authentication/utils/i18n/i18n.component';
import { I18nService } from './authentication/services/i18n.service';
import { ResendVerifyAttributeDirective } from './authentication/directives/resend-verify-attribute.directive';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCheck, faEye, faEyeSlash, faTimes, faCircleNotch, faExclamationTriangle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CookieService } from 'ngx-cookie-service';
import { LanguageSelectorComponent } from './authentication/utils/language-selector/language-selector.component';
import { PageNotFoundComponent } from './authentication/pages/page-not-found/page-not-found.component';
import { AdobeLaunchService } from './authentication/services/adobe-launch.service';
import { ConfirmRequiredMessageComponent } from './authentication/pages/confirm-account/confirm-required-message/confirm-required-message.component';
import { ConfirmResentMessageComponent } from './authentication/pages/confirm-account/confirm-resent-message/confirm-resent-message.component';
import { PasswordResetRequiredMessageComponent } from './authentication/pages/reset/password-reset-required-message/password-reset-required-message.component';
import { PasswordResetSentMessageComponent } from './authentication/pages/reset/password-reset-sent-message/password-reset-sent-message.component';
import { RegistrationCompleteMessageComponent } from './authentication/pages/registration/registration-complete-message/registration-complete-message.component';
import { ConfirmAccountFailedMessageComponent } from './authentication/pages/confirm-account/confirm-account-failed-message/confirm-account-failed-message.component';
import { ConfirmAccountSuccessMessageComponent } from './authentication/pages/confirm-account/confirm-account-success-message/confirm-account-success-message.component';
import { OwnershipTakenMessageComponent } from './authentication/pages/connect-ownership/ownership-taken-message/ownership-taken-message.component';
import { ChallengeFailedMessageComponent } from './authentication/pages/challenge/challenge-failed-message/challenge-failed-message.component';
import { BaseMessageComponent } from './authentication/utils/base-message.component';
import { LanguageService } from './authentication/services/language.service';
import { InternationalPhoneNumberModule } from './intl-phone';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from './shared-components/shared.module';
import { AccountSettingsComponent } from './authentication/pages/account-settings/account-settings.component';
import { GenericErrorComponent } from './authentication/pages/generic-error/generic-error.component';
import { InvalidVerificationComponent } from './authentication/pages/invalid-verification/invalid-verification.component';
import {WelcomeComponent} from './authentication/pages/welcome/welcome.component';
import {AccountComponent} from './account/account.component';


@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  imports: [],
  declarations: []
})
export class DisplayModule {}

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    LoginComponent,
    LoginFormComponent,
    ResetComponent,
    RegistrationComponent,
    ManageAccountComponent,
    LogoutComponent,
    OwnerLookupFormComponent,
    RegisterFormComponent,
    ResetFormComponent,
    ChallengeFormComponent,
    ChallengeComponent,
    ForcedUpdatePasswordFormComponent,
    AuthorizeComponent,
    ConfirmAccountComponent,
    ConfirmAccountFormComponent,
    ConnectOwnershipComponent,
    VerifyEmailComponent,
    VerifyPhoneComponent,
    PageNotFoundComponent,
    I18nComponent,
    ResendVerifyAttributeDirective,
    LanguageSelectorComponent,
    ConfirmRequiredMessageComponent,
    ConfirmResentMessageComponent,
    PasswordResetRequiredMessageComponent,
    PasswordResetSentMessageComponent,
    RegistrationCompleteMessageComponent,
    ConfirmAccountFailedMessageComponent,
    ConfirmAccountSuccessMessageComponent,
    OwnershipTakenMessageComponent,
    ChallengeFailedMessageComponent,
    BaseMessageComponent,
    AccountSettingsComponent,
    GenericErrorComponent,
    InvalidVerificationComponent,
    AppComponent,
    WelcomeComponent,
    AccountComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DisplayModule,
    BrowserAnimationsModule,
    InternationalPhoneNumberModule,
    HttpClientModule,
    PasswordStrengthMeterModule,
    FontAwesomeModule,
    SharedModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [I18nService, CookieService, AdobeLaunchService, LanguageService],
  bootstrap: [AppComponent],
  entryComponents: []
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCheck, faTimes, faEye, faEyeSlash, faCircleNotch, faExclamationTriangle, faChevronRight);
    window.digitalData = window.digitalData || {pageInfo: {}, userInfo: {}};
  }
}
