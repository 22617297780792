import { Directive, HostListener, Input, Output } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[appResendVerifyAttribute]'
})
export class ResendVerifyAttributeDirective {

  @Input() attribute: string;
  // @Output resentVerification;

  constructor(private authService: AuthenticationService) { }

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.attribute === 'phone') {
      this.authService.resendVerifyPhone().then().catch();
    } else if (this.attribute === 'email') {
      this.authService.resendVerifyEmail().then().catch();
    }
  }
}
