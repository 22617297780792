import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseMessageComponent } from '../../../utils/base-message.component';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-reset-sent-message',
  templateUrl: './password-reset-sent-message.component.html',
  styleUrls: ['./password-reset-sent-message.component.scss']
})
export class PasswordResetSentMessageComponent extends BaseMessageComponent implements OnInit {

  @Input() medium: string;
  @Input() deliveryMedium: string;
  @Input() destination: string;
  @Input() username: string;
  @Output() resend = new EventEmitter<any>();
  @Output() navigateToLogin = new EventEmitter<any>();
  browserIEorFF: any;
  constructor(private titleService: Title, private router: Router) {
    super();
  }

  ngOnInit() {
    this.titleService.setTitle('Account Located | Vistana Signature Experience');

  }
  ngAfterViewInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  sendPasswordReset() {
    this.startProcessing();
    this.resend.emit();
  }
  routeToLogin() {
    this.navigateToLogin.emit('Login - Failed - Reset Required - Done');
  }
}
