import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from '../base-form.component';

@Component({
  selector: 'app-confirm-account-form',
  templateUrl: './confirm-account-form.component.html',
  styleUrls: ['./confirm-account-form.component.scss']
})
export class ConfirmAccountFormComponent extends BaseFormComponent {
  verificationCode: string;

  @Input()
  username: string;

  @Output()
  confirmAccount = new EventEmitter<FormGroup>();

  @Output()
  resend = new EventEmitter<string>();

  constructor() {
    super();
  }

  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      verificationCode: [null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
    });
  }

  verify() {
    this.form.setErrors(null);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.startProcessing();
    this.confirmAccount.emit(this.form.value);
  }
}
