import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetFormComponent } from '../../forms/reset-form/reset-form.component';
import { AuthenticationService } from '../../services/authentication.service';
import { OwnerLookupFormComponent } from '../../forms/owner-lookup-form/owner-lookup-form.component';
import { AdobeLaunchService } from '../../services/adobe-launch.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  @ViewChild('resetForm', { static: false }) private reset: ResetFormComponent;
  @ViewChild('lookupForm', { static: false }) private lookup: OwnerLookupFormComponent;
  resetType = 'username';
  deliveryDetails: any;
  title= 'Reset Password'
  showAccountNotFound:boolean;
  showErrorMessage:boolean;
  emailPhoneNotVerified:boolean;
  resetMessage:string;
  accountNotAssociated:boolean;
  showSuccess:boolean;
  accountDisabled:boolean;
  username:string;

  constructor(private router: Router, private route: ActivatedRoute,
              private authService: AuthenticationService, private launch: AdobeLaunchService, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle('Reset | Vistana Signature Experiences');
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Password Reset';
    const type: string = (window.location.hash).toLowerCase();
    if (type === '#username' || type === '#ownership') {
      this.resetType = (type).substring(1, type.length);
    }
  }
  ngAfterViewInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  async resetPassword(event: any) {
    let username = event.username.trim();
    this.username = username;
    this.showErrorMessage = false;
    this.emailPhoneNotVerified = false;
    this.accountDisabled = false;
    this.accountNotAssociated = false;
    this.showAccountNotFound = false;
    try {
      this.deliveryDetails = (await this.authService.reset(username)).CodeDeliveryDetails;
      this.launch.eventTrack('Password Reset by Username - Success', {username: username});
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        this.accountNotAssociated = true;
        this.resetMessage = "You've entered an invalid username."
        this.deliveryDetails = {
          "deliveryMedium":"EMAIL",
          "destination":this.generateEmail()
        }
        this.deliveryDetails.DeliveryMedium = "EMAIL";
        this.deliveryDetails.Destination = this.generateEmail();
        this.launch.eventTrack('Password Reset by Username - Failed - User Not Found', {username: username});
      } else if (err.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
        this.emailPhoneNotVerified = true;
        this.resetMessage = "Cannot reset password for the user as there is no registered/verified email or phone_number"
        this.launch.eventTrack('Password Reset by Username - Failed - No Verified Communication', {username: username});
      } else if(err.message === "User is disabled.") {
        this.showErrorMessage = true;
        this.resetMessage = "Your account is no longer active. For assistance please email or contact Owner Services.";
        this.launch.eventTrack('Password Reset by Username - Failed - Account Not Active', {username: username});
      } else if(err.code === 'LimitExceededException'){
        this.showErrorMessage = true;
        this.resetMessage = "You have made multiple reset password requests. More incorrect attempts may result in more delays. Please try again later.";
      } else if(err.code === 'InvalidParameterException') {
          this.deliveryDetails = {
          "deliveryMedium":"EMAIL",
          "destination":this.generateEmail()
        }
        this.deliveryDetails.DeliveryMedium = "EMAIL";
        this.deliveryDetails.Destination = this.generateEmail();
      } else {
          this.showErrorMessage = true;
          this.resetMessage = "An unexpected error occurred. Please try again"
          this.launch.eventTrack('Password Reset by Username - General Error', {username: username});
    }
  }
  }

  public resetByContract(res: any):void {

    this.accountNotAssociated = res['accountNotAssociated'];
    this.accountDisabled = res['accountDisabled'];
    this.emailPhoneNotVerified = res['emailPhoneNotVerified'] || res['emailPhoneNotPresent'];
    this.resetMessage = res['resetMessage'];
    this.showErrorMessage = res['showErrorMessage'];
    if(this.emailPhoneNotVerified || this.accountDisabled || this.accountNotAssociated) {
      this.showErrorMessage = false;
    }

    if(res['codeDeliveryDetails'] != null) {
      this.deliveryDetails = res['codeDeliveryDetails']
      this.deliveryDetails.DeliveryMedium = res['codeDeliveryDetails'].deliveryMedium;
      this.deliveryDetails.Destination = res['codeDeliveryDetails'].destination;
      this.launch.eventTrack('Password Reset by Contract - Success', {});
    } else if(!this.showErrorMessage && !this.emailPhoneNotVerified && !this.accountNotAssociated && !this.accountDisabled) {
      this.deliveryDetails = {
        "deliveryMedium":"EMAIL",
        "destination":this.generateEmail()
      }
      this.deliveryDetails.DeliveryMedium = "EMAIL";
      this.deliveryDetails.Destination = this.generateEmail();
      this.launch.eventTrack('Password Reset by Contract - Failed - Ownership Not Found', {});
    }

}

  resetPage() {

    this.launch.eventTrack('Login - Failed - Reset Required - Resend Password Reset Link', {username: this.username});
    this.deliveryDetails = null;
  }
  resetStateNavigate(event: any){
    this.launch.eventTrack('Password Reset - Done', {username: this.username});
    this.resetPage();
    window.location.hash = '';
    this.router.navigate(['/']);
  }

  setResetType(type: string) {
    this.resetType = type;
  }

  generateEmail(): string {
    const charset = 'abcdefghijklmnopqrstuvwxyz';
    return charset.charAt(Math.floor(Math.random() * charset.length)) +
      '***@' + charset.charAt(Math.floor(Math.random() * charset.length)) +
      '***.com';
  }
}
