import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from './authentication/services/language.service';
import { Angulartics2LaunchByAdobe } from 'angulartics2/launch';

declare global {
  interface Window { digitalData: any; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  public constructor(@Inject(DOCUMENT) private document: Document, private titleService: Title, private languageService: LanguageService,
                     angulartics2LaunchByAdobe: Angulartics2LaunchByAdobe) {
    this.document.documentElement.lang = this.languageService.determineLanguage().code;

    let title = 'Vistana Signature Experience';

    this.titleService.setTitle(title);
    angulartics2LaunchByAdobe.startTracking();
  }
}
