import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { SsoService } from '../../services/sso.service';
import { AdobeLaunchService } from '../../services/adobe-launch.service';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent implements OnInit, AfterViewChecked {
  @ViewChild('ssoform', { static: true }) ssoForm: ElementRef;
  token: string;
  refreshToken: string;
  state: string;
  redirectUri: string;
  posting = false;

  constructor(@Inject(DOCUMENT) private document: any, private route: ActivatedRoute, private router: Router,
              private authService: AuthenticationService, private ssoService: SsoService, private launch: AdobeLaunchService) {}

  async ngOnInit() {
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Authorize';

    const params = this.route.snapshot.queryParams;
    const uri = decodeURIComponent(params.redirect_uri);
    const state = params.state;
    const newParams = {redirect_uri: null, state};

    this.posting = false;

    if (!(await this.authService.isAuthenticated()) || !this.ssoService.isSpValid(uri)) {
      if (this.ssoService.isSpValid(uri)) {
        newParams.redirect_uri = uri;
      }
      this.router.navigate(['/login'], {queryParams: newParams});
    } else {
      const user = await this.authService.getCurrentUser();
      this.redirectUri = uri;
      this.state = state;
      this.token = user.signInUserSession.accessToken.jwtToken;
      this.refreshToken = user.signInUserSession.refreshToken.token;
    }
  }

  ngAfterViewChecked() {
    if (this.redirectUri && this.token && !this.posting) {
      this.posting = true;
      this.ssoForm.nativeElement.submit();
    }
  }
}
