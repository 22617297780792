import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from '../base-form.component';

@Component({
  selector: 'app-reset-form',
  templateUrl: './reset-form.component.html',
  styleUrls: ['./reset-form.component.scss']
})
export class ResetFormComponent extends BaseFormComponent {
  @Output()
  resetPassword = new EventEmitter<FormGroup>();

  constructor() {
    super();
  }

  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      username: [null, Validators.compose([Validators.required])],
    });
  }

  reset() {
    this.form.setErrors(null);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    let usernameVal = this.form.get('username').value.trim().replace(/ /g,'_');
    this.form.patchValue({username: usernameVal});
    this.startProcessing();
    this.resetPassword.emit(this.form.value);
  }

  trimValue(val){
    const item = this.form.get(val);    
    if(item.value){item.setValue(item.value.trim())}      
  }
}
