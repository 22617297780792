import { OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export abstract class BaseFormComponent implements OnInit, OnDestroy {
  private warningSubscription: Subscription;
  public form: FormGroup;

  warnings = {};

  public processing = false;

  constructor() { }

  ngOnInit() {
    this.createForm();
    this.warningSubscription = this.form.valueChanges.subscribe(() => {
      this.warnings = {};
    });
  }

  public abstract createForm();

  startProcessing() {
    this.processing = true;
  }

  processingComplete() {
    this.processing = false;
  }

  ngOnDestroy(): void {
    if (this.warningSubscription) {
      this.warningSubscription.unsubscribe();
    }
  }
}
