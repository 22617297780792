import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterFormComponent } from '../../forms/register-form/register-form.component';
import { AuthenticationService } from '../../services/authentication.service';
import { AdobeLaunchService } from '../../services/adobe-launch.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @ViewChild('registerForm', { static: false }) public registerForm: RegisterFormComponent;
  deliveryDetails: any;
  destination: string;
  title="Registration";
  browserIEorFF:boolean = false;

  constructor(private router: Router, private authService: AuthenticationService, private launch: AdobeLaunchService) { }

  ngOnInit() {
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Register Account';
    //IE fix
    window.navigator.userAgent.includes('Trident/') || window.navigator.userAgent.includes('Firefox/') ? this.browserIEorFF = true : this.browserIEorFF = false;    
  }

  
  async register(event: any) {    
    //Add country name from form's selected country
    event["countryCode"] = this.registerForm.countrySelected['countryCode'];
    this.registerForm.processingSub = true;
    try {
      this.deliveryDetails = (await this.authService
        .register(event)).codeDeliveryDetails;
        //*****TODO: Find out where redirect is from or set at******
      if (this.deliveryDetails.DeliveryMedium === 'EMAIL') {       
        this.destination = this.registerForm.form.get('email').value;        
      } else {       
        this.destination = this.registerForm.form.get('phone').value;        
      }
      this.launch.eventTrack('Registration - Successful', {username: event.username});     
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (err) {
      console.error(err);
      this.registerForm.processingSub = false;
      this.registerForm.processingComplete();
      
      if (err.code === 'UsernameExistsException' || err.code === 'AliasExistsException') {
        this.registerForm.form.get('username').setErrors({usernameTaken: true});
        this.registerForm.form.setErrors({usernameTaken: true});
        this.launch.eventTrack('Registration - Failed - Username Taken', {username: event.username});         
      } else if (err.message === 'Invalid email address format.') {
        this.registerForm.form.get('email').setErrors({pattern: true});
      } else {
        this.registerForm.warnings = {generalError: true};
        this.launch.eventTrack('Registration - Failed - General Error', {username: event.username});
      }
      //Test if navigator is online
      navigator.onLine ? this.registerForm.onlineStatus = true : this.registerForm.onlineStatus = false;
      //Bring field with error to view
      this.registerForm.checkForErrors();
    }
  }
}
