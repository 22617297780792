import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-message',
  template: '',
  styleUrls: []
})
export class BaseMessageComponent implements OnInit {

  public processing = false;

  constructor() {}

  ngOnInit() {}

  toggleProcessing() {
    this.processing = this.processing;
  }

  startProcessing() {
    this.processing = true;
  }

  processingComplete() {
    this.processing = false;
  }
}
