import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-complete-message',
  templateUrl: './registration-complete-message.component.html',
  styleUrls: ['./registration-complete-message.component.scss']
})
export class RegistrationCompleteMessageComponent implements OnInit {

  @Input() medium: string;
  @Input() destination: string;
  @Input() browserIEorFF:boolean = false;

  constructor() { }

  ngOnInit() {}
}
