// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_OVFjUwHXP',
    userPoolWebClientId: '4or8dse0bmap79q89re9ond64c'
  },
  sso_issuers: [
    {
      redirect_uri: 'https://login-qa.vistana.com/sso/authenticate',
      homepage_uri: 'https://login-qa.vistana.com',
      logout_uri: 'https://login-qa.vistana.com/sso/logout',
      logout_endpoint: 'https://login-qa.vistana.com/sso/logout'
    }
  ],

  vistana_baseurl: "https://qa.vistana.com",
  idp_baseurl: 'https://login-qa.vistana.com',
  api_baseurl: 'https://api-qa.vistana.com',
  api_key: 'qsh878drjwnvky34j8f9599p',
  contactUs: 'https://www.vistana.com/contact-us',
  localization: 'false',
  title: 'Vistana Signature Experience',
  analytics: '//assets.adobedtm.com/72eef91a8bc6/b6d66cd43a1d/launch-ad6cc004e375-development.min.js'
};
