import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StatesCountries {
  constructor(private http: HttpClient) { }

  statesUrlGuest = environment.api_baseurl + '/exp/v1/countries?type=guest';
  statesUrlOwner = environment.api_baseurl + '/exp/v1/countries?type=owner';

  reqOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'api_key': environment.api_key
    })
  };

  getLocationsGuest() {
      return this.http.get(this.statesUrlGuest, this.reqOptions);
  }

  getLocationsOwner() {
    return this.http.get(this.statesUrlOwner, this.reqOptions);
  }
}
