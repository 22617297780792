import { Component, EventEmitter, Output, Input } from '@angular/core';
import { BaseMessageComponent } from '../../../utils/base-message.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-password-reset-required-message',
  templateUrl: './password-reset-required-message.component.html',
  styleUrls: ['./password-reset-required-message.component.scss']
})
export class PasswordResetRequiredMessageComponent extends BaseMessageComponent {
  @Input() browserIEorFF:boolean = false;
  @Input() unconfirmed:boolean = false;
  @Output() resend = new EventEmitter<any>();

  constructor(private titleService: Title) {
    super();
  }
  ngOnInit(){
    this.titleService.setTitle('Password Reset Required | Vistana Signature Experience');
  }
  ngAfterViewInit(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  sendPasswordReset() {
    this.startProcessing();
    this.resend.emit();
  }
}
