import {AbstractControl} from '@angular/forms';

export class EmailMatchValidation {

  static MatchEmail(form: AbstractControl) {

    const email = form.get('email').value;
    const confirmEmail = form.get('confirmEmail').value;

    if (email !== confirmEmail) {
      form.get('confirmEmail').setErrors( {MatchEmail: true} );
    } else if (email === confirmEmail) {
      form.get('confirmEmail').setErrors( null );
    } else {
      return null;
    }
  }
}
