import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit {

  @ViewChild('passwordStrengthMeter', {static: true}) private passwordStrengthMeter: PasswordStrengthMeterComponent;
  @Input() password: string;

  constructor(private i18nService: I18nService) { }

  ngOnInit() {}

  strength() {
    const strength = this.passwordStrengthMeter.passwordStrength;
    let strengthMessage = '';

    if (strength != null) {
      if (strength >= 0 && strength < 2) {
        try {
          strengthMessage = this.i18nService.get('weak');
        } catch (err) {
          strengthMessage = 'Weak';
        }
      } else if (strength >= 2 && strength < 4) {
        try {
          strengthMessage = this.i18nService.get('medium');
        } catch (err) {
          strengthMessage = 'Medium';
        }
        return strengthMessage;
      } else {
        try {
          strengthMessage = this.i18nService.get('strong');
        } catch (err) {
          strengthMessage = 'Strong';
        }
      }
    }

    return strengthMessage;
  }
}
