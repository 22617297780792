import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition,  
  } from '@angular/animations';

@Component({
    selector: 'app-toastr',
    templateUrl: './toastr-message.component.html',
    styleUrls: ['./toastr-message.component.scss'],
    animations: [
        trigger('toastrTrigger',[
          transition(':enter', [
            style({ opacity: 0 }),
            animate('0.5s', style({ opacity: 1 })),
          ]),
          transition(':leave', [
            animate('0.5s', style({ opacity: 0 }))
          ])
        ]),  
      ]
  })
  export class ToastrMessageComponent  implements OnInit {
    @Input() messageData:any;
    @Input() messageDisplay: boolean = false;
    @Input() message:Array<string>;
    @Output() toastrShowing = new EventEmitter<boolean>();
   
    ngOnInit(): void { 
        //Allows time component fade out before component is removed from the view
        setTimeout(()=> this.messageDisplay = false, 4000);
        //Sends notice to parent component to remove toastr from the view       
        setTimeout(()=>this.toastrShowing.emit(false), 5000)
    }
    //Toastr items expected from parent:
    // messageDisplay:boolean = false;

    // toastrShowing(evt){  
    //   this.messageDisplay = evt;
    // }
        
    // messageData:any = {} ;
    
    // messageDataUpdate = (outcome) =>{ 
    //   this.messageData = new Object;     
    //   this.messageData.type = outcome;
    //   if(outcome === 'success'){
    //     this.messageData.copy=['Successfully submitted.'];        
    //   } else if(outcome === 'error'){
    //     this.messageData.copy=['Account update failed. Please resubmit.'];
    //   }
    //   this.messageData.copy ? this.toastrShowing(true) : null;    //   
    // }
    //<app-toastr *ngIf="messageDisplay" [messageData]="messageData" [messageDisplay]="messageDisplay" (toastrShowing)="toastrShowing($event)"></app-toastr>
  }