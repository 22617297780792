import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordValidation } from '../../utils/password-validation';
import { BaseFormComponent } from '../base-form.component';
import { PasswordRequirementsComponent } from '../../utils/password-requirements/password-requirements.component';
import { Title } from '@angular/platform-browser';
import { PasswordReqsValidation } from '../../utils/password-reqs-validation';

@Component({
  selector: 'app-reset-confirm-form',
  templateUrl: './challenge-form.component.html',
  styleUrls: ['./challenge-form.component.scss']
})
export class ChallengeFormComponent extends BaseFormComponent implements OnInit {
  verificationCode: string;
  username: string;
  showPwHelp = false;  
  pwImg = 'assets/pw-hide.png';
  processingSub = false;
  @ViewChild('passwordReqComponent', { static: true }) private passwordReqComponent: PasswordRequirementsComponent;
  @Output() confirm = new EventEmitter<FormGroup>();
  @Input() errForToastr;
  

  constructor(private route: ActivatedRoute, private router: Router, private titleService: Title) {
    super();
  }

  ngOnInit() {
    this.titleService.setTitle('Reset Your Password | Vistana Signature Experience');
    const params = this.route.snapshot.queryParams;
    if (!params.username) {
      this.router.navigate(['/login']);
      return;
    }
    this.username = params.username;
    this.verificationCode = params.code;
    super.ngOnInit();
  }

  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      verificationCode: [this.verificationCode, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
      username: [this.username, Validators.compose([Validators.required])],
    }, {
      validator: [PasswordValidation.MatchPassword, PasswordReqsValidation.PasswordReqsValidation]
    });
  }
  
  // Eliminate spaces before or after a value. Accepts input name as argument.
  trimValue(val){
    const item = this.form.get(val);    
    if(item.value){item.setValue(item.value.trim())}      
  }
  resetConfirm() {
    this.form.setErrors(null);   
    if (this.form.invalid) {      
      this.form.markAllAsTouched();
      return;
    }    
    this.startProcessing();
    this.confirm.emit(this.form.value);
    this.processingSub = true;
  }
  //Toastr items expected from parent:
    messageDisplay:boolean = false;

    toastrShowing(evt){  
      this.messageDisplay = evt;
    }        
    messageData:any = {} ;    
    messageDataUpdate = (outcome) =>{ 
      this.messageData = new Object;     
      this.messageData.type = outcome;
      if(outcome === 'success'){
        this.messageData.copy=['Successfully submitted.'];        
      } else if(outcome === 'error'){
        this.messageData.copy= [this.errForToastr] || ['Something went wrong. Please re-enter new password and confirm.'] ;
      }
      this.messageData.copy ? this.toastrShowing(true) : null;  
    }
}
