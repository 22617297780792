import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PasswordUpdateFormComponent } from '../../forms/password-update-form/password-update-form.component';
import { Router } from '@angular/router';
import { UpdateUserFormComponent } from '../../forms/update-user-form/update-user-form.component';
import { SsoService } from '../../services/sso.service';
import { AdobeLaunchService } from '../../services/adobe-launch.service';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss']
})
export class ManageAccountComponent implements OnInit {

  @ViewChild('updatePasswordForm', { static: false }) private updatePasswordForm: PasswordUpdateFormComponent;
  @ViewChild('updateUserForm', { static: false }) private updateUserForm: UpdateUserFormComponent;

  selectedTab = 'contact-information';
  title = "Account Management"
  public user: any;

  constructor(private authService: AuthenticationService, public ssoService: SsoService, private launch: AdobeLaunchService) {}

  ngOnInit() {
    window.digitalData.pageInfo = {};
    window.digitalData.pageInfo.siteSection = 'Authentication Portal';
    window.digitalData.pageInfo.pageName = 'Authentication Portal | Manage Account';
    this.updateTab();
    this.authService.getCurrentUser(true).then(user => {
      this.user = user;
    });
  }

  @HostListener('window:hashchange')
  update() {
    const tab: string = (window.location.hash).toLowerCase();
    if (tab === '#security' || tab === '#contact-information') {
      this.selectedTab = (tab).substring(1, tab.length);
    }
  }

  async passwordUpdate($event) {
    try {
      await this.authService.updatePassword($event.oldPassword, $event.password);
      this.updatePasswordForm.form.reset();
      this.updatePasswordForm.success = true;
      this.updatePasswordForm.processingComplete();
      this.launch.eventTrack('Password Update - Success', {username: this.user.username});

      setTimeout(() => {
        if (this.updatePasswordForm) {
          this.updatePasswordForm.success = false;
        }
      }, 5000);
    } catch (err) {
      console.error(err);
      this.updatePasswordForm.processingComplete();
      if (err.code === 'NotAuthorizedException') {
        this.launch.eventTrack('Password Update - Failed - Invalid Password', {username: this.user.username});
        this.updatePasswordForm.form.get('oldPassword').setErrors({invalidPassword: true});
      } else {
        this.launch.eventTrack('Password Update - Failed - General Error', {username: this.user.username});
        this.updatePasswordForm.warnings = {generalError: true};
      }
    }
  }

  async userUpdate($event) {
    try {
      const username = ($event.username) ? ($event.username).toLowerCase() : null;
      const updateAttr = {email : $event.email, phone_number: null, preferred_username: username};
      const deleteAttr = [];

      if (username !== this.user.username) {
        updateAttr.preferred_username = username;
      } else if (username === this.updateUserForm.user.username && this.updateUserForm.user.attributes.preferred_username != null) {
        delete updateAttr.preferred_username;
        deleteAttr.push('preferred_username');
      }

      if ($event.phone) {
        updateAttr.phone_number = $event.phone;
      } else {
        deleteAttr.push('phone_number');
        delete updateAttr.phone_number;
      }

      this.updateUserForm.user = await this.authService.updateUser(updateAttr, deleteAttr);
      this.updateUserForm.success = true;
      this.updateUserForm.processingComplete();
      this.launch.eventTrack('Account Update - Success', {username});

      setTimeout(() => {
        if (this.updateUserForm) {
          this.updateUserForm.success = false;
        }
      }, 5000);
    } catch (err) {
      console.error(err);
      this.updateUserForm.processingComplete();
      if (err === 'UsernameExistsException' || err.code === 'AliasExistsException') {
        this.launch.eventTrack('Account Update - Failed - Username Taken', {username: $event.username});
        this.updateUserForm.form.setErrors({usernameTaken: true});
      } else if (err.message === 'Invalid email address format.') {
        this.updateUserForm.form.setErrors({pattern: true});
      } else {
        this.launch.eventTrack('Account Update - Failed - General Error', {username: $event.username});
        this.updateUserForm.warnings = {generalError: true};
      }
    }
  }

  setTab(tab) {
    window.location.hash = tab;
  }

  updateTab() {
    const tab: string = (window.location.hash).toLowerCase();
    if (tab === '#security' || tab === '#contact-information') {
      this.selectedTab = (tab).substring(1, tab.length);
    }
  }

  async logout() {
    await this.authService.logout();
    window.location.href = this.ssoService.getServicingSiteLogout();
  }
}
