import {AbstractControl} from '@angular/forms';

export class AddressFieldsValidation {

  static AddressFieldsSequence(form: AbstractControl) {

      const address1 = form.get('address1').value;
      const address2 = form.get('address2').value;
      const address3 = form.get('address3').value; 
    //Checks if first field is empty while any other is filled. If so, sets the error AddressFieldsSequence
    if ((address1 === "" || address1 === null) && (address2 !== null && address2 !=="") && (address3 !== null && address3 !== "")) { 
        form.get('address1').setErrors( {AddressFieldSequence: true} );    
    } else if((address1 === "" || address1 === null) && (address2 !== null && address2 !=="")){
        form.get('address1').setErrors( {AddressFieldSequence: true} );
    } else if((address1 === "" || address1 === null) && (address2 === null || address2 ==="") && (address3 !== null && address3 !== "")){
        form.get('address2').setErrors( {AddressFieldSequence: true} );
    } else if((address2 === "" || address2 === null)&& (address3 !== "" && address3 !== null)){
        form.get('address2').setErrors( {AddressFieldSequence: true} );
    } else { 
        //Delete has to be used to preserve other possible errors. github.com/angular/angular/issues/21564
        if(form.get('address1').errors && form.get('address1').errors.AddressFieldSequence){
            delete form.get('address1').errors.AddressFieldSequence;
        }  
        if(form.get('address2').errors && form.get('address2').errors.AddressFieldSequence){
            delete form.get('address2').errors.AddressFieldSequence;
        }  
        return null;
    }
  }
}
