import { Injectable } from '@angular/core';
import { Angulartics2LaunchByAdobe } from 'angulartics2/launch';

@Injectable({
  providedIn: 'root'
})
export class AdobeLaunchService {
  launch;

  constructor(launch: Angulartics2LaunchByAdobe) {
    this.launch = launch;
  }

  eventTrack(action: string, properties: any) {
    properties = properties || {};
    this.launch.eventTrack(action, properties);
  }
}
