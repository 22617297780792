import { Component, OnInit, Renderer } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Router } from '@angular/router';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  isOwner:boolean;
  connectedAccountShow:false;
  userAttributes;
  ownerAttributes;
  showLoading;

  constructor(private router: Router, private authService: AuthenticationService, public sanitizer: DomSanitizer, private renderer: Renderer) {
    if(this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.connected) {
      this.ownershipConnectedModalShow(true);
    }
  }

  //TODO: Modals are temporarily dismissable while in development. Once dev is complete, set ctaOnlyClose value to true for both objects to force user to click an option
  ownerCombine = {
    modalShows : false,
    ctaOnlyClose : false,
    headline: 'We noticed you may be using multiple accounts',
    messageCopy: 'This ownership account may have been merged with a different Online Account. If you have multiple logins, you are able to select "Logout" and try signing back in using your other logins. To proceed using this account as a Guest, which provides you access to your Guest Dashboard and Gallery, please select "Proceed as a Guest." If you need further assistance, please contact Owner Services at <a href="tel:800-847-8262">1-800-847-8262</a> or <a href="tel:407-903-4640">407-903-4640</a>.',
    buttons:[
      {
        copy: "Log Out",
        btnFunction:(evt)=>{
          //Must call evt.stopPropagation();
          evt.stopPropagation();
          this.router.navigate(['/logout']);
        },
        isCancelBtn: true
      },
      {
        copy: "Proceed as a Guest",
        btnFunction:async (evt)=>{
          //Must call evt.stopPropagation();
          evt.stopPropagation();
          this.authService.disconnectOwnership(this.user).subscribe(async res =>{
            await this.authService.getCurrentUser(true).then(user=>{this.user = user});
            this.ownerCombine.modalShows = false;
            this.authService.clearSessionAlt(this.router.url)
            document.querySelector('body').classList.remove('modal-open')
          }, error => {
            console.error(error);
          });
        }
      }
    ]
  }
  //Auto-disconnect modal data
  zeroContracts = {
    modalShows : false,
    ctaOnlyClose : false,
    headline: 'Hello',
    messageCopy: 'We noticed that there is no longer an ownership account connected to this profile. You will be redirected to the Dashboard. To connect a new ownership to your account, please click "Connect Ownership" on your Dashboard or call <a href="tel:800-847-8262">1-800-847-8262</a>.',
    buttons:[
      {
        copy: "OK",
        btnFunction:(evt)=>{
          //Must call evt.stopPropagation();
          evt.stopPropagation();
          this.router.navigate(['/dashboard']);
        },
        isCancelBtn: false
      }
    ]
  }

  footer: SafeHtml;
  title:string = 'Welcome Back';

  user: any;
  bodyEl = document.getElementsByTagName('body');
  ownershipMergeFailed;


  async ngOnInit() {
    this.user = await this.authService.getCurrentUser();
    this.userAttributes = this.user.attributes;
    if(this.user.attributes['custom:owner_status'] === '1') {
      this.isOwner = true;
    } else {
      this.isOwner = false;
    }
  }
  ownerCombineModalShow(evt){
    this.ownerCombine.modalShows = evt;
    evt ? this.bodyEl[0].classList.add('modal-open') : this.bodyEl[0].classList.remove('modal-open')
  }

  zeroContractsModalShow(evt){
    this.zeroContracts.modalShows = evt;
    evt ? this.bodyEl[0].classList.add('modal-open') : this.bodyEl[0].classList.remove('modal-open')
  }

  dcOwnershipModalShow(evt){
    this.dcOwnershipModal.modalShows = evt;
    evt ? this.bodyEl[0].classList.add('modal-open') : this.bodyEl[0].classList.remove('modal-open')
  }
  whyModalShow(evt){
    this.whyModal.modalShows = evt;
    evt ? this.bodyEl[0].classList.add('modal-open') : this.bodyEl[0].classList.remove('modal-open')
  }

  ownershipConnectedModalShow(evt) {
    this.ownershipConnectedModal.modalShows = evt;
    evt ? this.bodyEl[0].classList.add('modal-open') : this.bodyEl[0].classList.remove('modal-open')

  }

  getOwnerInfo() {
      this.showLoading = true;
      this.authService.getOwnerInfo(this.user).subscribe(async res => {
        this.ownershipMergeFailed.modalShows = true;
        this.ownerAttributes = res;
        this.showLoading = false;

        if(res['ownershipMergeFailed'] == 'true') {
            this.ownershipMergeFailed.modalShows = true;
        }

        if(res['ownerCombine'] == 'true') {
          this.ownerCombine.modalShows = true;
        }

        if(res['autoDisconnected'] == 'true') {
          this.zeroContracts.modalShows = true;
        }
      });
  }

  dcOwnershipModal = {
    modalShows : false,
    ctaOnlyClose : false,
    headline: "Are you sure",
    messageCopy:"By disconnecting your ownership account, you will no longer have access to view your ownership information online or view upcoming stays, pay maintenance fees or reserve online.",
    buttons:[
      {
        copy: "Keep Account Connected",
        btnFunction:(evt)=>{
          //Must call evt.stopPropagation();
          evt.stopPropagation();
          this.dcOwnershipModal.modalShows = false;
        },
        isCancelBtn: true
      },
      {
        copy: "Disconnect Account",
        btnFunction:async (evt)=>{
          //Must call evt.stopPropagation();
          evt.stopPropagation();
          this.authService.disconnectOwnership(this.user).subscribe(async res =>{
            await this.authService.getCurrentUser(true).then(user=>{this.user = user});
            this.isOwner = false;
            this.authService.clearSessionAlt(this.router.url)
            document.querySelector('body').classList.remove('modal-open')
            this.dcOwnershipModalShow(false);
          }, error => {
            console.error(error);
          });
        }
      }
    ]
  }
 whyModal = {
    modalShows : false,
    ctaOnlyClose : false,
    headline: "Why Connect?",
    messageCopy:"By connecting your ownership account with your Vistana online account, you can make online reservations, view upcoming stays, access your financial and Owners Association information and more.",
    buttons:[
      {
        copy: "Connect Now",
        btnFunction:(evt)=>{
          //Must call evt.stopPropagation();
          evt.stopPropagation();
          this.whyModal.modalShows = false
          this.router.navigate(['/settings/ownership'])
        }
      },
    ]
  }

  ownershipConnectedModal = {
    modalShows : false,
    ctaOnlyClose : false,
    headline: "Ownership Connected",
    messageCopy:"Now that you have connected your ownership to your Online Account, you can view your upcoming stays, pay maintenance fees and make reservations online.",
    // buttons:[
    //   {
    //     copy: "Connect Now",
    //     btnFunction:(evt)=>{
    //       //Must call evt.stopPropagation();
    //       evt.stopPropagation();
    //       this.whyModal.modalShows = false
    //       this.router.navigate(['/settings/ownership'])
    //     }
    //   },
    // ]
  }
}
