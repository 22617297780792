import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})

export class LoadingSpinnerComponent {

  faSpinner = faSpinner;
  
  @Input() isLoading: boolean = false;

  constructor() {}

}