import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseMessageComponent } from '../../../utils/base-message.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-resent-message',
  templateUrl: './confirm-resent-message.component.html',
  styleUrls: ['./confirm-resent-message.component.scss']
})
export class ConfirmResentMessageComponent extends BaseMessageComponent implements OnInit {
  @Input() browserIEorFF:boolean = false;
  @Input() medium: string;
  @Input() destination: string;
  @Input() showResend = false;
  @Output() resend = new EventEmitter<any>();

  constructor(private titleService: Title) {
    super();
  }
 
  ngOnInit() {this.titleService.setTitle('Almost Done | Vistana Signature Experience');}

  async resendConfirmation() {
    this.startProcessing();
    this.resend.emit();
  }
}
