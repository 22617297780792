import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterComponent } from 'src/app/authentication/pages/footer/footer.component';
import { ContrastBar } from 'src/app/shared-components/header/contrast-bar/contrast-bar.component';
import { HeaderComponent } from 'src/app/shared-components/header/header.component';
import { ModalComponent } from 'src/app/shared-components/general-modal/modal.component';

import { RouterModule } from '@angular/router';
import { AlertInfoComponent } from './alert-info/alert-info.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faTimes, faEye, faEyeSlash, faCircleNotch, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { VerificationStatusService } from '../authentication/services/verification-status.service';
import { HideShowFieldComponent } from '../authentication/utils/hide-show-field/hide-show-field.component';
import { PasswordRequirementsComponent } from '../authentication/utils/password-requirements/password-requirements.component';
import { PasswordStrengthComponent } from '../authentication/utils/password-strength/password-strength.component';
import { LoadingSpinnerComponent } from 'src/app/shared-components/loading-spinner/loading-spinner.component';
import { PasswordStrengthMeterComponent, PasswordStrengthMeterService, PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ButtonSpinnerComponent } from './loading-spinner/button-spinner/button-spinner.component';
import { ToastrMessageComponent } from './toastr-message/toastr-message.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ContrastBar,
    ModalComponent,
    AlertInfoComponent,
    HideShowFieldComponent,
    PasswordRequirementsComponent,
    PasswordStrengthComponent,
    LoadingSpinnerComponent,
    ButtonSpinnerComponent,
    ToastrMessageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    PasswordStrengthMeterModule
  ],
  exports: [
    HeaderComponent, 
    FooterComponent,   
    ContrastBar,
    ModalComponent,
    AlertInfoComponent,
    HideShowFieldComponent,
    PasswordRequirementsComponent,
    PasswordStrengthComponent,
    LoadingSpinnerComponent,
    ButtonSpinnerComponent,
    ToastrMessageComponent
  ],
  providers: [VerificationStatusService]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCheck, faTimes, faEye, faEyeSlash, faCircleNotch, faExclamationTriangle, faSpinner);
  }
}