import {AbstractControl} from '@angular/forms';

export class PasswordReqsValidation { 
    
  static PasswordReqsValidation(form: AbstractControl) {
  
    const passwordField = form.get('password').value; 
    if(passwordField){
        const isValid = passwordField.length >= 8 && (/[A-Z]/.test(passwordField)) && (/[a-z]/.test(passwordField)) && (/[0-9]/.test(passwordField)) && (/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/.test(passwordField)) && (/[^+=]/.test(passwordField)); 

        !isValid ? form.get('password').setErrors({pwNotValid:true}): form.get('password').setErrors(null)
    }
  }  
}