import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'
import { AuthenticationService } from './../../authentication/services/authentication.service';
import { VerificationStatusService } from 'src/app/authentication/services/verification-status.service';
import { environment } from '../../../environments/environment'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {

  @Input() title: string;
  @Input() showSubNav: Boolean = false;
  @Input() showWelcomeBar: Boolean = false;
  @Input() verifiedState: Boolean = true;
  unverifiedCount: number;
  user;
  // Mock data object
  headerContent = {
    title: "Ownership Account",
    guestWelcome: this.user ? `Welcome, ${this.user.attributes.given_name}` : "Welcome",
    logo: {
      link: environment.vistana_baseurl,
      image: "https://vse-content.s3.amazonaws.com/www/images/vistana_logo_web.png"
    },
    headerImage0: {
      link: environment.vistana_baseurl + "/sheraton-vacation-club",
      image: "https://vse-content.s3.amazonaws.com/www/images/logo-sheraton-gray.png"
    },
    headerImage1: {
      link: environment.vistana_baseurl + "/westin-vacation-club",
      image: "https://vse-content.s3.amazonaws.com/www/images/logo-westin-gray.png"
    },
    topRowLinks: [
      {
        link: "/settings",
        copy: "Account Settings"
      },
      {
        link: "/logout",
        copy: "Log Out"
      }
    ],
    bottomRowLinks: [
      {
        link: "/settings/account-summary",
        copy: "Account Summary"
      },
      {
        link: "/settings/security",
        copy: "Login & Security"
      },
      {
        link: "/settings/change-password",
        copy: "Change Password"
      },
      {
        link: "/settings/communication-preferences",
        copy: "Communication Preferences"
      },
      {
        link: "/settings/contact-information",
        copy: "Address & Phone"
      },
    ],

    dashboardLink: {
        link: environment.vistana_baseurl + '/dashboard',
        copy: "Dashboard"
    }
  }
  currentRoute: string;
  constructor(private router: Router, private authService: AuthenticationService, private verificationStatusService: VerificationStatusService) { }

  async ngOnInit() {
    this.currentRoute = this.router.url;
    if(this.verifiedState){
      this.user = await this.authService.getCurrentUser();
    }
    this.verificationStatusService.currentCount.subscribe(currentCount => {
      this.unverifiedCount = currentCount;
    });
    this.headerContent.guestWelcome = this.user ? `Welcome, ${this.user.attributes.given_name}` : "Welcome"
  }

}
