import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../../utils/password-validation';
import { PasswordRequirementsComponent } from '../../utils/password-requirements/password-requirements.component';
import { BaseFormComponent } from '../base-form.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forced-update-password-form',
  templateUrl: './forced-update-password-form.component.html',
  styleUrls: ['./forced-update-password-form.component.scss']
})
export class ForcedUpdatePasswordFormComponent extends BaseFormComponent {
  @ViewChild('passwordReqComponent', { static: true }) private passwordReqComponent: PasswordRequirementsComponent;
  showPwHelp = false;
  pwImg = 'assets/pw-hide.png';
  processingSub:boolean = false;

  @Input()
  currentUsername: string;

  @Output()
  update = new EventEmitter<FormGroup>();

  constructor(private titleService: Title) {
    super();
  }
  ngAfterViewInit(){
    //Must call title service from this lifecycle hook to not interfere with form functions
    this.titleService.setTitle('Reset Your Password | Vistana Signature Experience');
  }

  createForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      password: [null, Validators.compose([Validators.required])],
      confirmPassword: [null, Validators.compose([Validators.required])],
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  // Eliminate spaces before or after a value. Accepts input name as argument.
  viewPwToggle: any;
  trimValue(val){
    const item = this.form.get(val);
    if(item.value){item.setValue(item.value.trim())}
  }

  updatePassword() {
    this.form.setErrors(null);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.startProcessing();
    this.processingSub = true;
    this.update.emit(this.form.value);
  }
}
