import {AbstractControl} from '@angular/forms';

export class PasswordValidation {

  static MatchPassword(form: AbstractControl) {

    const password = form.get('password').value;
    const confirmPassword = form.get('confirmPassword').value;

    if (password !== confirmPassword) {
      form.get('confirmPassword').setErrors( {MatchPassword: true} );
    } else if (password === confirmPassword) {
      form.get('confirmPassword').setErrors( null );
    } else {
      return null;
    }
  }
}
