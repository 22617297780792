import { Component, Input } from '@angular/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { AdobeLaunchService } from '../../authentication/services/adobe-launch.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss']
})

export class AlertInfoComponent {

  @Input() alertImg: string;
  @Input() alertIcon: boolean;
  @Input() alertLvl: string;
  @Input() alertMsg: string;
  @Input() ctaLink: string;
  @Input() ctaCopy: string;
  @Input() verificationLink: string;
  @Input() verificationMsg: string;
  @Input() verificationEmail: string;
  @Input() verificationPhone: string;
  @Input() verificationSentMsg: string = 'Verification link has been sent!';
  @Input() verificationFailMsg: string = 'Attempt limit exceeded, please try after some time.'
  emailVerificationSent: boolean = false;
  emailVerificationSendFailure: boolean = false;
  phoneVerificationSent: boolean = false;
  phoneVerificationSendFailure: boolean = false;
  faExclamationTriangle = faExclamationTriangle;

  constructor(private authService: AuthenticationService, private launch: AdobeLaunchService, private router: Router) { }

  sendVerificationLink(verificationType) {
    this.authService.getCurrentUser(true).then(user => {
      if (verificationType === 'email') {
        this.authService.resendVerifyEmail().then(() => {
          this.emailVerificationSent = true;
          this.launch.eventTrack('Verify Email - Resent', { username: user.username });
        }).catch(err => {
          console.error(err);
          if(err.code === "LimitExceededException") {
            this.emailVerificationSendFailure = true;
          }
        });
      }

      if (verificationType === 'phone') {
        this.authService.resendVerifyPhone().then(() => {
          this.phoneVerificationSent = true;
          this.launch.eventTrack('Verify Phone - Resent', { username: user.username });
        }).catch(err => {
          console.error(err);
          if(err.code === "LimitExceededException") {
            this.phoneVerificationSendFailure = true;
          }
        });
      }

      setTimeout(() => {
        if (this.emailVerificationSent) {
          this.emailVerificationSent = false;
        }
        if (this.phoneVerificationSent) {
          this.phoneVerificationSent = false;
        }
        if (this.emailVerificationSendFailure) {
          this.emailVerificationSent = false;
        }
        if (this.phoneVerificationSendFailure) {
          this.phoneVerificationSent = false;
        }
      }, 5000);
    });
  }
}