import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  enabled = 'true';
  selectedLanguage = this.languageService.languages[0];

  constructor(private route: ActivatedRoute, private router: Router, private languageService: LanguageService) {}

  ngOnInit() {
    this.enabled = environment.localization;
    this.selectedLanguage = this.languageService.determineLanguage();
  }

  swapLanguage(locale) {
    const currentLocale = '/' + this.selectedLanguage.code;
    this.selectedLanguage = this.languageService.findLanguage(locale);
    if (currentLocale === '/en') {
      window.location.href = location.origin + '/' + this.selectedLanguage.code + location.pathname;
    } else {
      const newPath = (this.selectedLanguage.code === 'en') ? '' : '/' + this.selectedLanguage.code;
      const newLocation = window.location.href.split(currentLocale).join(newPath);
      window.location.href = newLocation;
    }
  }
}
