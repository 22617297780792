import { Component, EventEmitter, Output, Input } from '@angular/core';
import { BaseMessageComponent } from '../../../utils/base-message.component';

@Component({
  selector: 'app-challenge-failed-message',
  templateUrl: './challenge-failed-message.component.html',
  styleUrls: ['./challenge-failed-message.component.scss']
})
export class ChallengeFailedMessageComponent extends BaseMessageComponent {
  @Input() browserIEorFF:boolean = false;
  @Output() resend = new EventEmitter<any>();

  constructor() {
    super();
  }

  resendPasswordReset() {
    this.startProcessing();
    this.resend.emit();
  }
}
