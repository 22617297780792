import {Injectable} from '@angular/core';
import { Xliff, Xliff2 } from '@angular/compiler';
import { Error } from 'tslint/lib/error';

declare const require;
const content = require('raw-loader!../../../locale/messages.ja.xlf');

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private readonly xliff: any = new Xliff().load(content, '');

  get(key: string): string {
    if (window.location.pathname.split('/').length > 1) {
      const locale = window.location.pathname.split('/')[1];

      if (locale !== 'ja') {
        throw new Error();
      }
    }

    return this.xliff.i18nNodesByMsgId[key][0].value;
  }
}
