import { Component, OnInit } from '@angular/core';
import { BaseMessageComponent } from '../../../utils/base-message.component';

@Component({
  selector: 'app-confirm-account-success-message',
  templateUrl: './confirm-account-success-message.component.html',
  styleUrls: ['./confirm-account-success-message.component.scss']
})
export class ConfirmAccountSuccessMessageComponent extends BaseMessageComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {}
}
